import { Helmet } from "react-helmet-async";
import { MRT_Localization_TR } from "material-react-table/locales/tr";
import { faker } from "@faker-js/faker";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography, Box, Button } from "@mui/material";
// components
// sections
import Summary from "src/sections/@dashboard/app/Summary";
import { mainContainer } from "src/utils/cssStyles";
import PageHeader from "src/components/Pageheader/PageHeader";
import TaskIcon from "@mui/icons-material/Task";
import ConstructionIcon from "@mui/icons-material/Construction";
import {
  BarChart,
  LastTransactions,
  LineChart,
} from "src/sections/@dashboard/app";
import { altbox, alttext } from "src/theme/customStyles";
import Test from "./Test";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import global from "src/Global";
import { useNavigate } from "react-router-dom";
import LeafletMap from "src/components/LeafletMap/LeafletMap";
import Main from "./FinansalIslemler/Paket/Main";
import { Logout, Navigation } from "@mui/icons-material";
import { logOut } from "src/utils/helpers/helpers";
import ChoosePackage from "src/components/YobComponents/ChoosePackageComponent/ChoosePackage";
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [data, setData] = React.useState([]);
  const [time, setTime] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    x();
  }, []);

  function x() {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://dev.plancim.com/api/dashboard", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result);
        setTimeout(() => {
          setTime(false);
        }, 1500);

        if (result.statusCode == 401) {
          navigate("/401");
        }
      })
      .catch((error) => console.error(error));
  }

  if (data == []) {
    return <LoadingOverlay isOpen={true} />;
  }

  return (
    <>
      {data?.companyInfo?.remainingDays == 0 && <ChoosePackage />}

      <Box style={mainContainer}>
        <Helmet>
          <title> Anasayfa | Plancım </title>
        </Helmet>

        <LoadingOverlay isOpen={time} />

        <PageHeader title={"ANASAYFA"} subtitle={"Anasayfa > Anasayfa"} />

        <Box maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Summary
                title={"Toplam Proje"}
                amount={data?.totalProject}
                image={
                  <ConstructionIcon
                    color={"secondary"}
                    sx={{ fontSize: "24px" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Summary
                title={"Toplam Personel"}
                amount={data?.totalWorker}
                image={
                  <FontAwesomeIcon
                    icon={faUserTie}
                    color={"#0087c7"}
                    size="lg"
                  />
                }
              />{" "}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Summary
                title={"Alınan Teklif"}
                amount={data?.totalOffer}
                image={
                  <TaskIcon color={"secondary"} sx={{ fontSize: "24px" }} />
                }
              />{" "}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Summary
                packet={data?.companyInfo?.packet}
                title={data?.companyInfo?.name}
                amount={data?.companyInfo?.remainingDays + " Gün"}
                image={
                  <TaskIcon color={"secondary"} sx={{ fontSize: "24px" }} />
                }
                isCustom={true}
              />
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                {/* <LineChart title={`Alım Grafiği`} /> */}
                <LeafletMap />
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ height: "100vh" }} />
      </Box>
    </>
  );
}
