import { Box, Typography } from "@mui/material";
import React from "react";

function PageHeader(props) {
  const cont = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "2rem",
  };

  const text = {
    fontSize: { xs: "18px", md: "20px", lg: "24px" },
    fontWeight: "bold",
    color: "#181e2e",
    letterSpacing: "0.01em",
    marginRight: "auto",
  };

  const text2 = {
    fontSize: { xs: "12px", md: "14px", lg: "16px" },
    fontWeight: "bold",
    color: "primary.second",
    letterSpacing: "0.01em",
  };

  return (
    <Box sx={cont}>
      <Typography sx={text}>{props.title}</Typography>
      <Typography sx={text2}>{props.subtitle}</Typography>
    </Box>
  );
}

export default PageHeader;
