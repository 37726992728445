import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";

const box = {
  width: "240px",
  p: 2,
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
};

const btn = {
  width: "160px",
  alignSelf: "center",
  fontFamily: "Roboto, sans-serif",
  fontWeight: "normal",
  fontSize: "14px",
  color: "#fff",
  background: "linear-gradient(90deg, #2d2f43 0%, #03abff 100%)",
  padding: "10px 25px",
  border: "2px solid #0066cc",
  borderRadius: "50px",
  transition: "padding 1000ms, transform 1000ms",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  cursor: "pointer",
  marginTop: "1rem",
  "&:hover": {
    transform: " translateY(-5px)",
    background: "linear-gradient(90deg, #2d2f43 0%, #03abff 100%)",
    fontWeight: "bold",
    border: "solid 2px #0066cc",
    padding: "10px 50px",
  },
};

function Paket({ setSelectedTab, setSection }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));

    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://dev.plancim.com/api/payment/packet", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const updatedItems = result.items.map((item) => {
          return {
            ...item,
            description: item.description
              .split("\n")
              .map((line) => line.trim())
              .join("\n"),
          };
        });
        setData(updatedItems);
      })
      .catch((error) => console.error(error));
  }, []);

  if (!data) {
    return <LoadingOverlay isOpen={true} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      {data?.map((plan, index) => (
        <Box key={index} sx={box}>
          <Typography variant="h5">{plan.name}</Typography>
          {plan.description.split("\n").map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
          <Box
            sx={{
              justifyContent: "flex-end",
              display: "flex",
              flexDirection: "column",
              marginTop: "1.5rem",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {plan.price}
              <Typography
                sx={{
                  fontSize: 14,
                  opacity: 0.7,
                  paddingTop: 1,
                  marginLeft: "4px",
                }}
              >
                {" "}
                /ay
              </Typography>
            </Typography>
            <Typography gutterBottom sx={{ fontSize: "12px", opacity: 0.8 }}>
              {" "}
              Kdv Dahil
            </Typography>
          </Box>

          <Button
            variant="contained"
            color="primary"
            sx={btn}
            onClick={() => {
              setSelectedTab("Fatura");
              setSection(2);
            }}
          >
            Seç
          </Button>
        </Box>
      ))}
    </Box>
  );
}

export default Paket;
