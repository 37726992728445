import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import {
  Container,
  TextField,
  Typography,
  Box,
  Button,
  InputAdornment,
  IconButton,
  Snackbar,
} from "@mui/material";
import TwoFactorIcon from "../../../components/MyIcons/LoginIcons/TwoFactor";
import Logo from "../../../components/logo";
import useResponsive from "../../../hooks/useResponsive";
import Iconify from "src/components/iconify";
import { LoadingButton } from "@mui/lab";
import MuiAlert from "@mui/material/Alert";

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 700,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(4, 0),
}));

export default function ForgetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const mdUp = useResponsive("up", "md");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isDigit, setIsDigit] = useState(false);
  const [isSpecialChar, setIsSpecialChar] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(Boolean);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    if (token) {
      setHasToken(true);
    }
  }, [token]);

  const handleSubmit = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: email,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://dev.plancim.com/api/auth/forget-password", requestOptions)
      .then((response) => {
        if (response.ok) {
          setLoading(false);
          setAlertMessage("Mail adresinizi kontrol ediniz.");
          setAlertStatus(true);
          handleAlertClick();
          return response.json();
        } else {
          setLoading(false);
          setAlertMessage("İşlem Başarısız.");
          setAlertStatus(false);
          handleAlertClick();
        }
      })
      .then((result) => {
        console.log(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        // Show error message
      });
  };

  const handleSubmitVerifyToken = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      password: confirmPassword,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://dev.plancim.com/api/auth/forget/${token}`, requestOptions)
      .then((response) => {
        if (response.ok) {
          setLoading(false);
          setAlertMessage("İşlem Başarılı.");
          setAlertStatus(true);
          handleAlertClick();
          setTimeout(() => {
            navigate("/");
          }, 1200);

          return response.json();
        } else {
          setLoading(false);
          setAlertMessage("İşlem Başarısız.");
          setAlertStatus(false);
          handleAlertClick();
        }
      })
      .then((result) => {
        console.log(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (confirmPassword.length >= 8) {
      setIsLengthValid(true);
    } else {
      setIsLengthValid(false);
    }

    if (/[a-z]/.test(confirmPassword)) {
      setIsLowerCase(true);
    } else {
      setIsLowerCase(false);
    }

    if (/\d/.test(confirmPassword)) {
      setIsDigit(true);
    } else {
      setIsDigit(false);
    }

    if (/[!@#$%^&*()_+.]/.test(confirmPassword)) {
      setIsSpecialChar(true);
    } else {
      setIsSpecialChar(false);
    }

    if (/[A-Z]/.test(confirmPassword)) {
      setIsUpperCase(true);
    } else {
      setIsUpperCase(false);
    }

    if (password === confirmPassword) {
      setIsPasswordMatch(true);
    } else {
      setIsPasswordMatch(false);
    }

    if (
      isUpperCase &&
      isLowerCase &&
      isDigit &&
      isSpecialChar &&
      isLengthValid &&
      isPasswordMatch
    ) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
  }, [password, confirmPassword, isPasswordMatch]);

  return (
    <>
      <Helmet>
        <title> Giriş Doğrulama | Plancım</title>
      </Helmet>

      <StyledRoot>
        <StyledContent>
          <Logo sx={{ alignSelf: "center", width: 160, height: 160 }} />
          <Container
            maxWidth="sm"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "40vw",
              height: hasToken ? "65vh" : "50vh",
              minWidth: "400px",
              border: "1px solid #F4F6F8",
              borderRadius: "16px",
              backgroundColor: "#F9FAFB",
              webkitBoxShadow: "0px 0px 89px -41px rgba(42,48,66,1)",
              mozBoxShadow: "0px 0px 89px -41px rgba(42,48,66,1)",
              boxShadow: "0px 0px 89px -41px rgba(42,48,66,1)",
              overflow: "hidden",
            }}
          >
            {hasToken ? (
              <>
                <Box style={{ alignSelf: "center", marginBottom: "1rem" }}>
                  <TwoFactorIcon />
                </Box>
                <Typography
                  sx={{ mb: 3, color: "text.sabitMavi", textAlign: "center" }}
                >
                  Lütfen yeni şifrenizi belirleyiniz.
                </Typography>{" "}
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
                >
                  <TextField
                    name="confirmPassword"
                    label="Parola"
                    type={showPassword ? "text" : "password"}
                    sx={{ input: { color: "#2A3042" } }}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    name="confirmPassword"
                    label="Parolayı Onayla"
                    type={showPassword ? "text" : "password"}
                    sx={{ input: { color: "#2A3042" } }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "1rem",
                    }}
                  >
                    <Typography
                      style={{
                        color: isUpperCase ? "green" : "red",
                        fontSize: 12,
                        marginBottom: "0.5rem",
                      }}
                    >
                      • Bir büyük harf içermelidir
                    </Typography>
                    <Typography
                      style={{
                        color: isLowerCase ? "green" : "red",
                        fontSize: 12,
                        marginBottom: "0.5rem",
                      }}
                    >
                      • Bir küçük harf içermelidir
                    </Typography>
                    <Typography
                      style={{
                        color: isDigit ? "green" : "red",
                        fontSize: 12,
                        marginBottom: "0.5rem",
                      }}
                    >
                      • Bir rakam içermelidir
                    </Typography>
                    <Typography
                      style={{
                        color: isSpecialChar ? "green" : "red",
                        fontSize: 12,
                        marginBottom: "0.5rem",
                      }}
                    >
                      • Bir özel karakter içermelidir (.,!,*,+,^,(,),%)
                    </Typography>
                    <Typography
                      style={{
                        color: isLengthValid ? "green" : "red",
                        fontSize: 12,
                      }}
                    >
                      • En az 8 karakter içermelidir
                    </Typography>
                  </Box>

                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSubmitVerifyToken}
                    sx={{ my: 1 }}
                    disabled={buttonStatus}
                  >
                    Onayla
                  </LoadingButton>
                </Box>
              </>
            ) : (
              <>
                <Box style={{ alignSelf: "center", marginBottom: "2rem" }}>
                  <TwoFactorIcon />
                </Box>
                <Typography
                  variant="h6"
                  sx={{ mb: 4, color: "text.sabitMavi", textAlign: "center" }}
                >
                  Şifre yenileme bağlantısını gönderebilmemiz için e-posta
                  adresinize ihtiyacımız var.
                </Typography>
                <TextField
                  label="E-posta Adresiniz"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Yükleniyor..." : "Şifremi Yenile"}
                </Button>
              </>
            )}
          </Container>
        </StyledContent>
      </StyledRoot>

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </>
  );
}
