import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  text14wLeft,
  dvdr50,
  dvdrDik,
  dvdrfull,
  modalStyle,
  newModalStyle,
  subTitle,
  title14,
  title20,
  titleStyle,
} from "src/theme/customStyles";
import CloseIcon from "@mui/icons-material/Close";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import { modalStyleNewest } from "src/utils/cssStyles";

const DetailStatics = ({ modalVisible, setModalVisible, id, type }) => {
  const [cityDetail, setCityDetail] = useState(null);
  useEffect(() => {
    const fetchCityDetail = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("token"));
      myHeaders.append(
        "X-Organization-Id",
        localStorage.getItem("Organization")
      );
      myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `https://dev.plancim.com/api/static/${type}/${id}`,
          requestOptions
        );
        console.log(response);
        const result = await response.json();
        setCityDetail(result);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCityDetail();
  }, [id]);

  if (!cityDetail) {
    return <LoadingOverlay isOpen={true} />;
  }

  const {
    name,
    coordinate,
    altitude,
    snowLoad,
    earthquake,
    heatZone,
    frostDepth,
    centerDistance,
    waterPrice,
    sunRiseSunSet,
  } = cityDetail;

  return (
    <Modal
      open={modalVisible}
      onClose={() => setModalVisible(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyleNewest}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography style={titleStyle}>DETAY</Typography>
          <IconButton
            sx={{ alignSelf: "flex-end", marginLeft: "auto" }}
            color="black"
            onClick={() => setModalVisible(false)}
          >
            <CloseIcon sx={{ fontSize: "32px" }} />
          </IconButton>
        </Box>
        <Typography style={subTitle}>{name} Detayları</Typography>

        <Box
          style={{
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          <Typography
            sx={[
              title14,
              {
                alignItems: "center",
                display: "flex",
                textDecoration: "none",
              },
            ]}
          >
            Koordinatlar:{" "}
            <Typography
              sx={[text14wLeft, { fontSize: "12px", textAlign: "left" }]}
            >
              {coordinate.latitude}, {coordinate.longitude}
            </Typography>
          </Typography>
          <Typography
            sx={[title14, { display: "flex", textDecoration: "none" }]}
          >
            {" "}
            Rakım: <Typography sx={text14wLeft}>{altitude}</Typography>
          </Typography>
          <Typography
            sx={[title14, { display: "flex", textDecoration: "none" }]}
          >
            {" "}
            Kar Yükü : <Typography sx={text14wLeft}> {snowLoad}</Typography>
          </Typography>
          <Typography
            sx={[title14, { display: "flex", textDecoration: "none" }]}
          >
            {" "}
            Deprem: <Typography sx={text14wLeft}> {earthquake}</Typography>
          </Typography>
          <Typography
            sx={[title14, { display: "flex", textDecoration: "none" }]}
          >
            {" "}
            Merkeze Uzaklık:{" "}
            <Typography sx={text14wLeft}> {centerDistance}</Typography>
          </Typography>
          <Typography
            sx={[title14, { display: "flex", textDecoration: "none" }]}
          >
            {" "}
            Temiz Su Fiyatı :{" "}
            <Typography sx={text14wLeft}> {waterPrice.clean}</Typography>
          </Typography>
          <Typography
            sx={[title14, { display: "flex", textDecoration: "none" }]}
          >
            {" "}
            Atık Su Fiyatı :{" "}
            <Typography sx={text14wLeft}> {waterPrice.waste}</Typography>
          </Typography>
        </Box>

        <Box style={{ marginBottom: "24px" }}>
          <Typography sx={[title20]}>Sıcaklık Bölgeleri</Typography>
          <Box sx={[dvdr50, { marginBottom: "12px" }]} />
          <List
            sx={{
              bgcolor: "background.paper",
            }}
          >
            {Object.entries(heatZone).map(([month, temperature]) => (
              <ListItem key={month}>
                <Typography sx={[title14, { textDecoration: "none" }]}>
                  {month} :
                </Typography>
                <Typography sx={text14wLeft}>{temperature}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box style={{ marginBottom: "24px" }}>
          <Typography sx={[title20, { width: "200px" }]}>
            Donma Derinliği
          </Typography>
          <Box sx={[dvdr50, { marginBottom: "12px" }]} />

          <Box>
            <List
              sx={{
                bgcolor: "background.paper",
                borderRadius: "12px",
              }}
            >
              {Object.entries(frostDepth).map(([depth, value]) => (
                <ListItem key={depth}>
                  <Typography sx={[title14, { textDecoration: "none" }]}>
                    {depth} :{" "}
                  </Typography>{" "}
                  <Typography sx={text14wLeft}> {value}</Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>

        <Box style={{ marginBottom: "24px" }}>
          <Typography sx={[title20, { width: "300px" }]}>
            Gün Doğumu & Batımı
          </Typography>
          <Box sx={[dvdr50, { marginBottom: "12px" }]} />

          <Box>
            <List
              sx={{
                bgcolor: "background.paper",
                height: "400px",
                borderRadius: "12px",
                overflowY: "scroll",
              }}
            >
              {Object.entries(sunRiseSunSet).map(([day, monthData]) => (
                <div key={day}>
                  <ListItem>
                    <ListItemText primary={`Gün ${day}`} />
                  </ListItem>
                  <List>
                    {Object.entries(monthData).map(([month, data]) => (
                      <ListItem key={month}>
                        <ListItemText
                          primary={`${month}: Doğum - ${data.rise}, Batım - ${data.set}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
              ))}
            </List>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DetailStatics;
