import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Turk from "../../../components/LeafletMap/geojson/Turkey.json";
import L from "leaflet"; // Leaflet'in 'L' nesnesini içe aktarın
import { Box, Button, List, ListItem } from "@mui/material";
import { Helmet } from "react-helmet-async";
import PageHeader from "src/components/Pageheader/PageHeader";
import { mainContainer } from "src/utils/cssStyles";
import { getMapCities } from "src/utils/services/HaritaApis.js/api";

const initialCenter = [39, 35]; // Haritanın başlangıç konumu
const initialZoom = 6; // Haritanın başlangıç zoom seviyesi
const HaritaGorunumu = () => {
  const [currentIlData, setCurrentIlData] = useState(null);
  const [buttonType, setButtonType] = useState("Meteorolojik Veriler");
  const [district, setDistrict] = useState(null);
  const [city, setCity] = useState(null);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const onEachFeature = (feature, layer) => {
    layer.on({
      click: () => onFeatureClick(feature),
    });
  };

  const onEachFeatureCity = (feature, layer) => {
    layer.on({
      click: () => onFeatureClickCity(feature),
    });
  };

  const onFeatureClick = (feature) => {
    const ilAdi = feature.properties.name;
    getMapCities(feature.properties.name, setData);

    // Seçili ilin GeoJSON dosyasını dinamik olarak içe aktar
    import(`../../../components/LeafletMap/geojson/${ilAdi}.json`)
      .then((module) => {
        console.log(module);
        setCurrentIlData(module.default);
        console.log("Current Il Data:", module.default);
      })
      .catch((error) => {
        console.error("Error loading geojson data:", error);
      });
  };

  const onFeatureClickCity = (feature) => {
    const IlceAdi = feature.properties.ILCE_ADI;
    setDistrict(IlceAdi);
    console.log(city);
    console.log(IlceAdi);
  };

  const ZoomToBounds = ({ data }) => {
    const map = useMap();

    useEffect(() => {
      if (data) {
        const geoJsonLayer = new L.GeoJSON(data);
        map.fitBounds(geoJsonLayer.getBounds()); // Haritayı sınırlarına sığdırır
      }
    }, [data, map]);

    return data ? (
      <GeoJSON
        data={data}
        style={{ color: "#0087c7", weight: 1 }}
        onEachFeature={onEachFeatureCity}
      />
    ) : null;
  };

  const ZoomToBoundsTurkey = ({ data }) => {
    const map = useMap();

    useEffect(() => {
      if (data) {
        const geoJsonLayer = new L.GeoJSON(data);
        map.fitBounds(geoJsonLayer.getBounds()); // Haritayı sınırlarına sığdırır
      }
    }, [data, map]);

    return data ? (
      <GeoJSON
        data={data}
        onEachFeature={onEachFeature}
        style={{ color: "#2A3042", weight: 1 }}
      />
    ) : null;
  };

  const handleBackButtonClick = () => {
    setCurrentIlData(null);
  };

  const handleSwitch = (item) => {
    setButtonType(item);
  };

  return (
    <Box sx={[mainContainer]}>
      <Helmet Helmet>
        <title> Harita| Plancım </title>
      </Helmet>

      <PageHeader
        title={"HARİTA GÖRÜNÜMÜ"}
        subtitle={"Veriler > Harita Görünümü"}
      />
      <Box sx={{ flexDirection: "row", display: "flex" }}>
        <MapContainer
          center={initialCenter}
          zoom={initialZoom}
          style={{
            width: "100vw",
            height: "70vh",
            borderRadius: "16px",
            borderTopRightRadius: currentIlData != null ? 0 : null,
            borderBottomRightRadius: currentIlData != null ? 0 : null,
          }}
          zoomControl={false}
          scrollWheelZoom={false}
          dragging={false}
          touchZoom={false}
          doubleClickZoom={false}
          boxZoom={false}
          keyboard={false}
        >
          {currentIlData != null && (
            <Button
              style={{
                position: "absolute",
                top: 50,
                left: 50,
                zIndex: 444,
                textTransform: "none",
              }}
              variant="contained"
              onClick={() => handleBackButtonClick()}
              size="small"
            >
              Geri Dön
            </Button>
          )}

          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {currentIlData == null && <ZoomToBoundsTurkey data={Turk} />}
          {currentIlData && <ZoomToBounds data={currentIlData} />}
        </MapContainer>
        {currentIlData != null && (
          <Box
            sx={{
              width: "50%",
              height: "100%",
              marginLeft: "12px",
              borderRadius: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  textTransform: "none",
                  backgroundColor:
                    buttonType != "Meteorolojik Veriler"
                      ? "text.disabled"
                      : null,
                }}
                size="small"
                onClick={() => handleSwitch("Meteorolojik Veriler")}
              >
                Meteorolojik Veriler
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  backgroundColor:
                    buttonType == "Meteorolojik Veriler"
                      ? "text.disabled"
                      : null,
                  textTransform: "none",
                }}
                size="small"
                onClick={() => handleSwitch("Firma Bul")}
              >
                Firma Bul
              </Button>
            </Box>

            {data != [] && (
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  position: "relative",
                  overflow: "auto",
                  maxHeight: "62.5vh",
                  marginTop: "24px",
                  "& ul": { padding: 0 },
                }}
              >
                <div>
                  <ul>
                    <li>
                      <strong>ID:</strong> {data?.id}
                    </li>
                    <li>
                      <strong>Plate:</strong> {data?.plate}
                    </li>
                    <li>
                      <strong>Coordinate:</strong>
                      <ul>
                        <li>Latitude: {data?.coordinate?.latitude}</li>
                        <li>Longitude: {data?.coordinate?.longitude}</li>
                      </ul>
                    </li>
                    <li>
                      <strong>Snow Load:</strong> {data?.snowLoad}
                    </li>
                    <li>
                      <strong>Earthquake:</strong> {data?.earthquake}
                    </li>
                    <li>
                      <strong>Heat Zone:</strong>
                      {data?.heatZone && (
                        <ul>
                          {Object.entries(data?.heatZone).map(
                            ([key, value]) => (
                              <li key={key}>
                                {key.toUpperCase()}: {value}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </li>
                    <li>
                      <strong>Frost Depth:</strong>
                      {data?.frostDepth && (
                        <ul>
                          {Object.entries(data?.frostDepth).map(
                            ([key, value]) => (
                              <li key={key}>
                                {key.toUpperCase()}: {value}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </li>
                    <li>
                      <strong>Waste Water:</strong> {data?.wasteWater || "N/A"}
                    </li>
                    <li>
                      <strong>Neighboring Provinces:</strong>
                      <ul>
                        {data?.neighboringProvinces?.map((province) => (
                          <li key={province?.id}>
                            {province?.name} - Length: {province?.length} km
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <strong>State:</strong> {data?.state?.name} (ID:{" "}
                      {data?.state?.id})
                    </li>
                    <li>
                      <strong>Districts:</strong>
                      <ul>
                        {data?.districts?.map((district) => (
                          <li key={district?.id}>{district?.name}</li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </div>
              </List>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HaritaGorunumu;
