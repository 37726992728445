import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {
  modalStyle,
  subTitle,
  text14wLeft,
  title14,
  title20,
  titleStyle,
} from "src/theme/customStyles";

import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import { modalStyleNewest } from "src/utils/cssStyles";

function TitleDetail(props) {
  const [modalVisible, setModalVisible] = React.useState(props.detailModal);
  const [facilityDetails, setFacilityDetails] = React.useState({
    name: "",
    description: "",
    isMain: false,
  });

  React.useEffect(() => {
    props.setDetailModal(modalVisible);
  }, [modalVisible]);

  React.useEffect(() => {
    if (modalVisible) {
      fetchFacilityDetails();
    }
  }, [modalVisible]);

  const fetchFacilityDetails = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://dev.plancim.com/api/title/${props.selectedIdUpdate}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setFacilityDetails(result);
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Modal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyleNewest}>
          <Box
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography style={titleStyle}>DETAY</Typography>
            <IconButton
              sx={{ alignSelf: "flex-end" }}
              color="black"
              onClick={() => setModalVisible(false)}
            >
              <CloseIcon sx={{ fontSize: "32px" }} />
            </IconButton>
          </Box>

          <Box
            style={{
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Typography sx={[title14]}>Ünvan Adı : </Typography>
              <Typography sx={text14wLeft}> {facilityDetails.name}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginTop: "12px",
                alignItems: "CENTER",
              }}
            >
              <Typography sx={title14}>Ünvan Açıklaması:</Typography>
              <Typography sx={text14wLeft}>
                {" "}
                {facilityDetails?.description
                  ? facilityDetails.description
                  : "Açıklama bulunmamaktadır."}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default TitleDetail;
