import { Box, Typography, TextField, Button } from "@mui/material";
import React, { useState } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Iconify from "src/components/iconify";
import uuid from "react-uuid";

function AddModal({
  setAlertStatus,
  setAlertMessage,
  handleAlertClick,
  setAddModal,
}) {
  const [email, setEmail] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");

  const addUsers = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      phone: {
        code: "90",
        number: phoneNumber,
      },
      password: password,
      profile: {
        name: name,
        surname: surname,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`https://dev.plancim.com/api/user/${uuid()}`, requestOptions)
      .then((response) => {
        return response.json().then((data) => {
          if (response.ok) {
            console.log(response);
            setAlertStatus(true);
            setAlertMessage("Yeni kullanıcı başarıyla eklendi.");
            handleAlertClick();

            setTimeout(() => {
              setAddModal(false);
            }, 1000);
          } else {
            // API'den gelen hata mesajını ayarla
            setAlertMessage(data.message);
            setAlertStatus(false);
            handleAlertClick();
          }
        });
      })
      .catch((error) => {
        // Hata durumunda mesajı ayarla
        setAlertMessage(error.message || "Bir hata oluştu.");
        setAlertStatus(false);
        handleAlertClick();
      });
  };

  return (
    <Box>
      <Box sx={style}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Box
            style={{
              marginBottom: "24px",
              width: "100px",
              height: "100px",
              backgroundColor: "#F4F6F8",
              borderRadius: 999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PersonAddIcon color="success" sx={{ fontSize: "64px" }} />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ color: "primary.main" }}
          >
            Yeni Kullanıcı Ekle
          </Typography>

          <Box
            style={{
              width: "50%",
              height: "0.01rem",
              backgroundColor: "#181e2e",
              opacity: 0.2,
              marginTop: "1%",
              borderRadius: 16,
            }}
          />
        </Box>

        <TextField
          name="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
        />

        <TextField
          name="phoneNumber"
          label="Telefon Numarası"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          style={{ marginBottom: "1rem" }}
        />
        <TextField
          name="password"
          label="Şifre"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginBottom: "1rem" }}
        />
        <TextField
          name="name"
          label="İsim"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ marginBottom: "1rem" }}
        />
        <TextField
          name="surname"
          label="Soyisim"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
          style={{ marginBottom: "1rem" }}
        />

        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={() => addUsers()}
          color="success"
          style={{ color: "white", marginTop: "1rem" }}
        >
          <span>Yeni Kullanıcı Ekle</span>
        </Button>
      </Box>
    </Box>
  );
}

export default AddModal;

const style = {
  position: "absolute",
  transform: {
    xs: "translate(5%, 10%)",
    md: "translate(100%, 10%)",
    lg: "translate(200%, 10%)",
  },
  width: "373px",

  bgcolor: "background.paper",
  border: "1px solid #181e2e",
  boxShadow: 24,
  p: 4,
  justifyContent: "flex-start",
  display: "flex",
  flexDirection: "column",
  borderRadius: 4,
};
