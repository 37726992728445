import * as React from "react";
const VerifyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="icon"
    width={80}
    height={80}
    fill="#2A3042"
    viewBox="0 0 32 32"
    {...props}
  >
    <g id="SVGRepo_iconCarrier">
      <defs>
        <style>{".cls-1{fill:none}"}</style>
      </defs>
      <path d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2Zm-2 19.59-5-5L10.59 15 14 18.41 21.41 11l1.596 1.586Z" />
      <path
        id="inner-path"
        d="m14 21.591-5-5L10.591 15 14 18.409 21.41 11l1.595 1.585L14 21.591z"
        className="cls-1"
      />
      <path
        id="_Transparent_Rectangle_"
        d="M0 0h32v32H0z"
        className="cls-1"
        data-name="&lt;Transparent Rectangle&gt;"
      />
    </g>
  </svg>
);
export default VerifyIcon;
