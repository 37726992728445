export const getMapCities = async (city, setData) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", localStorage.getItem("token"));
  myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
  myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  // URL'i oluştururken searchText parametresini koşullu olarak ekleyin
  let url = `https://dev.plancim.com/api/static/city/map/${city}`;

  // selectedCity null değilse, URL'e ekliyoruz

  fetch(url, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log("XXXXXX");
      setData(result);
      console.log(result);
      console.log("XXXXXX");
    })
    .catch((error) => console.log("error", error));
};
