import { Box, Typography, TextField, Button } from "@mui/material";
import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
function DeleteModal({
  setAlertStatus,
  setAlertMessage,
  handleAlertClick,
  setDeleteModal,
  selectedId,
}) {
  const deleteUser = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`https://dev.plancim.com/api/user/${selectedId}`, requestOptions)
      .then((response) => {
        return response.json().then((data) => {
          if (response.ok) {
            console.log(response);
            setAlertStatus(true);
            setAlertMessage("Kullanıcı başarıyla silindi");
            handleAlertClick();

            setTimeout(() => {
              setDeleteModal(false);
            }, 1000);
          } else {
            setAlertMessage(data.message.join(", "));
            setAlertStatus(false);
            handleAlertClick();
          }
        });
      })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        setAlertMessage("Bir hata oluştu.");
        setAlertStatus(false);
        handleAlertClick();
      });
  };

  return (
    <Box
      sx={[
        style,
        {
          width: "%30",
          height: "30%",
          top: "25%",
          justifyContent: "center",
          alignItems: "center",
        },
      ]}
    >
      <Box
        style={{
          marginBottom: "24px",
          width: "100px",
          height: "100px",
          backgroundColor: "#F4F6F8",
          borderRadius: 999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DeleteForeverIcon color="error" sx={{ fontSize: "64px" }} />
      </Box>
      <Typography
        id="modal-modal-title"
        variant="h5"
        component="h3"
        sx={{
          color: "primary.main",
          marginBottom: "1rem",
          textAlign: "center",
        }}
      >
        Mesleği silmek istediğinize emin misiniz ?
      </Typography>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="success"
          sx={{
            color: "white",
            marginRight: "1rem",
            fontSize: 16,
            borderRadius: 16,
            width: "120px",
          }}
          endIcon={<CheckIcon />}
          onClick={() => deleteUser()}
        >
          Evet
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{
            color: "error",
            marginRight: "1rem",
            fontSize: 16,
            borderRadius: 16,
            width: "120px",
          }}
          endIcon={<CloseIcon />}
          onClick={() => setDeleteModal(false)}
        >
          Vazgeç
        </Button>
      </Box>
    </Box>
  );
}

export default DeleteModal;

const style = {
  position: "absolute",
  transform: {
    xs: "translate(5%, 10%)",
    md: "translate(100%, 10%)",
    lg: "translate(200%, 10%)",
  },
  width: "373px",

  bgcolor: "background.paper",
  border: "1px solid #181e2e",
  boxShadow: 24,
  p: 4,
  justifyContent: "flex-start",
  display: "flex",
  flexDirection: "column",
  borderRadius: 4,
};
