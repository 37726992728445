import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import Paket from "./Paket";
import { title14 } from "src/theme/customStyles";
import { mainContainer } from "src/utils/cssStyles";

// Paket, Fatura ve Ödeme bileşenleri
const InvoiceComponent = () => <div>Fatura Component</div>;
const PaymentComponent = () => <div>Ödeme Component</div>;

const Main = () => {
  const [selectedTab, setSelectedTab] = useState("Paket");
  const [section, setSection] = useState(1);

  const btn = {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "normal",
    fontSize: "14px",
    color: "#fff",
    background: "linear-gradient(90deg, #2d2f43 0%, #03abff 100%)",
    padding: "10px 30px",
    border: "2px solid #0066cc",
    transition: "padding 1000ms, transform 1000ms",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      padding: "10px 50px",
      background: "linear-gradient(90deg, #2d2f43 0%, #03abff 100%)",
      fontWeight: "bold",
      border: "solid 2px #0066cc",
    },
  };

  const btn2 = {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "normal",
    fontSize: "14px",
    background: "linear-gradient(90deg, #DFE3E8  100%, #03abff 100%);",
    padding: "10px 30px",
    border: "1px solid #D3D3D3",
    transition: "padding 1000ms, transform 1000ms",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      padding: "10px 50px",
      fontWeight: "bold",
      border: "1px solid #383838",
    },
  };

  return (
    <Box sx={mainContainer}>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          border: "0px solid #ccc",
          borderRadius: "16px 16px 16px 16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #F4F6F8",
            mb: 2,
          }}
        >
          <Button
            variant={selectedTab === "Paket" ? "contained" : "outlined"}
            sx={section === 1 || section || 2 ? btn : btn2}
            onClick={() => {
              setSelectedTab("Paket");
              setSection(1);
            }}
          >
            <Typography sx={[title14, { color: "white" }]}>Paket</Typography>
          </Button>
          <Button
            variant={selectedTab === "Fatura" ? "contained" : "outlined"}
            sx={section == 2 || section == 3 ? btn : btn2}
            disabled={true}
          >
            <Typography sx={[title14, { color: "white" }]}>Fatura</Typography>
          </Button>
          <Button
            variant={selectedTab === "Ödeme" ? "contained" : "outlined"}
            sx={selectedTab === "Ödeme" ? btn : btn2}
            disabled={true}
          >
            <Typography sx={[title14, { color: "white" }]}>Ödeme</Typography>
          </Button>
        </Box>
        <Box sx={{ p: 4 }}>
          {selectedTab === "Paket" && (
            <Paket setSelectedTab={setSelectedTab} setSection={setSection} />
          )}
          {selectedTab === "Fatura" && <InvoiceComponent />}
          {selectedTab === "Ödeme" && <PaymentComponent />}
        </Box>
        {/* Seçilen sekmenin bileşenini gösterme */}
      </Box>
    </Box>
  );
};

export default Main;
