export const modalStyle = {
  position: "absolute",
  width: "30%",
  bgcolor: "background.paper",
  border: "0px solid #181e2e",
  boxShadow: 24,
  p: 4,
  justifyContent: "flex-start",
  display: "flex",
  flexDirection: "column",
  borderRadius: 4,
  alignSelf: "center",
  justifySelf: "center",
  left: { xs: "5%", md: "27.5%", lg: "27.5%" },
  top: "10%",
};

export const newModalStyle = {
  position: "absolute",
  bgcolor: "background.paper",
  border: "0px solid #181e2e",
  boxShadow: 24,
  p: 4,
  justifyContent: "flex-start",
  display: "flex",
  flexDirection: "column",
  borderRadius: 4,
  transform: {
    xs: "translate(0%, 10%)",
    md: "translate(100%, 10%)",
    lg: "translate(200%, 10%)",
  },
};

export const frm1 = {
  marginTop: "10px",
  justifyContent: "center",
  flexDirection: "column",
  display: "flex",
  alignItems: "center",
};

export const title14 = {
  fontSize: "14px",
  fontWeight: "600",
  color: "text.sabitMavi",
  textDecoration: "underline",
  textAlign: "center",
};

export const text14 = {
  fontSize: "14px",
  color: "text.primary",
  textAlign: "center",
};

export const text14wLeft = {
  fontSize: "14px",
  color: "text.primary",
  textAlign: "center",
  marginLeft: "4px",
};

export const dvdrDik = {
  height: "15px",
  width: "1px",
  backgroundColor: "secondary.main",
  display: "flex",
  marginRight: "16px",
  marginLeft: "16px",
  opacity: "0.5",
};
export const titleStyle = {
  fontSize: "24px",
  fontWeight: "bold",
  color: "#181e2e",
  letterSpacing: "0.01em",
};

export const titleBox = {
  fontSize: "24px",
  fontWeight: "bold",
  color: "#383838",
  letterSpacing: "0.05em",
};

export const titleBox16 = {
  fontSize: "16px",
  fontWeight: "bold",
  color: "#383838",
  letterSpacing: "0.05em",
};

export const title20 = {
  fontSize: "20px",
  fontWeight: "bold",
  color: "primary.main",
  letterSpacing: "0.05em",
};

export const subTitle = {
  fontSize: "16px",
  fontWeight: "bold",
  color: "primary.second",
  letterSpacing: "0.01em",
};

export const alttext = {
  fontSize: "14px",
  opacity: 0.6,
  color: "primary.second",
  letterSpacing: "0.01em",
  fontStyle: "italic",
};

export const miniBoxes = {
  display: "flex",
  flexDirection: "row",
  gap: 1,
  marginBottom: "24px",
  flexWrap: "wrap",
};

export const rlminibox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "12px",
  backgroundColor: "#495166",
  borderRadius: "12px",
  height: "30px",
};

export const columncntr = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export const rowcntr = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};

export const dvdr50 = {
  width: "50%",
  height: "0.01rem",
  backgroundColor: "#181e2e",
  opacity: 0.2,
  marginTop: "1%",
  borderRadius: 16,
  marginBottom: "2rem",
};
export const dvdr50yzd = {
  width: "50%",
  height: "0.01rem",
  backgroundColor: "#181e2e",
  opacity: 0.2,
  borderRadius: 16,
};

export const dvdrfull = {
  width: "100%",
  height: "0.01rem",
  backgroundColor: "#181e2e",
  opacity: 0.05,
  borderRadius: 16,
  marginBottom: "auto",
  marginTop: "5%",
};

export const altbox = {
  width: "100%",
  height: "30px",
  backgroundColor: "#eff0f1",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  padding: "10px",
  gap: "1rem",
};

export const renderTopToolbarCustomActionsCont = {
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "1rem",
  marginTop: "1rem",
  marginBottom: "2rem",
};

export const paginationCont = {
  alignItems: "flex-end",
  display: "flex",
  flexDirection: "column",
  marginTop: "1rem",
  marginRight: "1rem",
  marginBottom: "2rem",
  marginLeft: "auto",
};

export const inPag = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginLeft: "auto",
};

export const titleContainer = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "2rem",
};

export const pageTitle = {
  fontSize: "24px",
  fontWeight: "bold",
  color: "#181e2e",
  letterSpacing: "0.01em",
  marginRight: "auto",
};

export const filtreTemize = {
  backgroundColor: "WARNING",
  fontSize: "14px",
  marginLeft: "16px",
  marginBottom: "12px",
};

export const pagIns = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginRight: "1rem",
};

export const tolbr = {
  display: "flex",
  height: "80px",
  justifyContent: "space-between",
  alignItems: "center",
};

export const tlbarins = {
  display: "flex",
  gap: "1rem",
  p: "0.5rem",
  flexWrap: "wrap",
};

export const rowins = {
  display: "flex",
  flexWrap: "nowrap",
  flexDirection: "row",
  gap: "1rem",
  width: "100px",
};
