import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import {
  Box,
  Grid,
  IconButton,
  ListItem,
  Modal,
  Typography,
} from "@mui/material";
import { newModalStyle, subTitle, titleStyle } from "src/theme/customStyles";
import CloseIcon from "@mui/icons-material/Close";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import { modalStyleNewest } from "src/utils/cssStyles";

export default function MaterialAnaDetail({
  modalVisible,
  setModalVisible,
  type,
  id,
}) {
  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState([]);

  const handleClick = (id) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [id]: !prevOpen[id],
    }));
  };

  React.useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));

    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://dev.plancim.com/api/material/title/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setData(result);
      })
      .catch((error) => console.error(error));
  }, [id]);

  if (!data) {
    return <LoadingOverlay isOpen={true} />;
  }

  const { types, materials } = data;

  return (
    <>
      {data != [] && (
        <Modal
          open={modalVisible}
          onClose={() => setModalVisible(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyleNewest}>
            <Box
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography style={titleStyle}>DETAY</Typography>
              <IconButton
                sx={{ alignSelf: "flex-end", marginLeft: "auto" }}
                color="black"
                onClick={() => setModalVisible(false)}
              >
                <CloseIcon sx={{ fontSize: "32px" }} />
              </IconButton>
            </Box>
            <Typography style={subTitle}>{type} Detayları</Typography>

            <Box
              style={{
                gap: "1rem",
                display: "flex",
                flexDirection: "column",
                marginTop: "24px",
                marginBottom: "24px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "background.paper",
                  display: "flex",
                  padding: 4,
                  flexDirection: "column",
                  borderRadius: 6,
                  width: "343px",
                  maxWidth: 360,
                  gap: "1rem",
                }}
              >
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                  aria-labelledby="material-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="material-list-subheader">
                      Malzemeler
                    </ListSubheader>
                  }
                >
                  {materials?.map((material) => (
                    <ListItem key={material.id} divider>
                      <ListItemText primary={material.name} />
                    </ListItem>
                  ))}

                  {materials?.length < 1 && (
                    <ListItem divider>
                      <ListItemText
                        primary={"Malzeme bulunmamaktadır."}
                        sx={{
                          opacity: "0.7",
                          textAlign: "center",
                        }}
                      />
                    </ListItem>
                  )}
                </List>

                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Alt Başlıklar
                    </ListSubheader>
                  }
                >
                  {types?.map((type) => (
                    <React.Fragment key={type.id}>
                      <ListItemButton onClick={() => handleClick(type.id)}>
                        <ListItemText primary={type.name} />
                        {open[type.id] ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={open[type.id]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {type.materials.map((material) => (
                            <ListItemButton key={material.id} sx={{ pl: 4 }}>
                              <ListItemText primary={material.name} />
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  ))}

                  {types?.length < 1 && (
                    <ListItem divider>
                      <ListItemText
                        primary={"Alt başlık bulunmamaktadır."}
                        sx={{
                          opacity: "0.7",
                          textAlign: "center",
                        }}
                      />
                    </ListItem>
                  )}
                </List>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
