import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import {
  Box,
  List,
  ListItemText,
  Collapse,
  ListItem,
  Typography,
} from "@mui/material";

//
import { useState } from "react";
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandMore, ExpandLess } from "@mui/icons-material"; // MUI ikonları ekleniyor

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  // "Menü" ve "Ayarlar" başlıkları altında gruplandırma yapılacak
  const menuItems = data.filter(
    (item) =>
      item.title !== "Finansal İşlemler" &&
      item.title !== "Ayarlar" &&
      item.title !== "Kullanıcı"
  );
  const settingsItems = [
    ...data.filter((item) => item.title === "Kullanıcı"),
    ...data.filter(
      (item) => item.title === "Finansal İşlemler" || item.title === "Ayarlar"
    ),
  ];

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {/* Menü Başlığı */}
        <ListItem>
          <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
            MENU
          </Typography>
        </ListItem>
        {menuItems.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}

        {/* Ayarlar Başlığı */}
        {settingsItems.length > 0 && (
          <>
            <ListItem>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "bold", marginTop: "12px" }}
              >
                KULLANICI PANELİ
              </Typography>
            </ListItem>
            {settingsItems.map((item) => (
              <NavItem key={item.title} item={item} />
            ))}
          </>
        )}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, subMenu } = item;
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <StyledNavItem
        component={subMenu ? "div" : RouterLink}
        to={subMenu ? null : path}
        onClick={subMenu ? handleToggle : null}
        size={"small"}
        sx={{
          "&.active": {
            color: "text.primary",
            bgcolor: "action.selected",
            fontWeight: "fontWeightBold",
          },
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {info && info}
        {subMenu && (open ? <ExpandLess /> : <ExpandMore />)}
      </StyledNavItem>

      {subMenu && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subMenu.map((subItem) => (
              <NavItem key={subItem.title} item={subItem} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
