import { Box, Button, Card, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  alttext,
  dvdrfull,
  titleBox,
  titleBox16,
  titleStyle,
} from "src/theme/customStyles";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";

function Summary(props) {
  const [packageX, setPackageX] = useState("Demo");

  const StyledIcon = {
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    width: "40px",
    height: "40px",
    justifyContent: "center",
    backgroundColor: "#eff0f1",
  };

  return (
    <>
      {props.isCustom ? (
        <Card
          sx={{
            boxShadow: 0,
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            p: 2,
            height: "90px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Box sx={[StyledIcon, { marginRight: "16px" }]}>
              <PersonIcon />
            </Box>

            <Box sx={{}}>
              <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                {props.title}
              </Typography>
              <Typography sx={titleBox}>
                {props.amount == null ? "0" : props.amount}
              </Typography>
            </Box>

            <IconButton
              style={{
                width: "80px",
                height: "20px",
                backgroundColor: "#eff0f1",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "auto",
              }}
            >
              <Typography sx={[alttext, { opacity: 1, fontStyle: "normal" }]}>
                {props.packet}
              </Typography>
            </IconButton>
          </Box>
        </Card>
      ) : (
        <Card
          sx={{
            boxShadow: 0,
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Box sx={{ marginRight: "auto" }}>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              {props.title}
            </Typography>
            <Typography sx={titleBox}>
              {props.amount == null ? "0" : props.amount}
            </Typography>
          </Box>

          <Box sx={StyledIcon}>{props.image}</Box>
        </Card>
      )}
    </>
  );
}

export default Summary;
