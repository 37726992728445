import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Main from "src/pages/FinansalIslemler/Paket/Main";
import { logOut } from "src/utils/helpers/helpers";

const ChoosePackage = () => {
  const navigate = useNavigate();

  return (
    <Box
      style={{
        top: 0,
        left: 0,
        zIndex: 24239047328947324732,
        position: "absolute",
        width: "100vw",
        height: "1000px",
        backgroundColor: "#1b1b1bb4",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        display: "flex",
      }}
    >
      <Box
        style={{
          width: "700px",
          height: "500px",
          marginBottom: "100px",
          position: "relative",
        }}
      >
        <Main />
        <Button
          sx={{
            position: "absolute",
            right: 30,
            top: 8,
            alignSelf: "center",
            textTransform: "none",
          }}
          variant="outlined"
          size="small"
          onClick={() => {
            logOut();
            navigate("/");
          }}
        >
          Çıkış Yap
        </Button>
      </Box>
    </Box>
  );
};

export default ChoosePackage;
