import React, { useState } from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { modalStyleNewest } from "src/utils/cssStyles";
import { text14wLeft, title14, titleStyle } from "src/theme/customStyles";

const TesisDetailModal = (props) => {
  const [company, setCompany] = useState([]);
  React.useEffect(() => {
    getCompany();
  }, [props.detailModal]);

  const getCompany = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`http://85.104.112.4:400/api/facility/${props.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCompany(result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {company && (
        <Modal
          open={props.detailModal}
          onClose={() => props.setDetailModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={[modalStyleNewest]}>
            <Box
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={titleStyle}>Şirket Detayları</Typography>
              <IconButton
                sx={{ alignSelf: "flex-end" }}
                color="black"
                onClick={() => props.setDetailModal(false)}
              >
                <CloseIcon sx={{ fontSize: "32px" }} />
              </IconButton>
            </Box>

            <Box>
              <Typography
                sx={[title14, { display: "flex", textDecoration: "none" }]}
              >
                {" "}
                İsim: <Typography sx={text14wLeft}> {company?.name}</Typography>
              </Typography>
              <Typography
                sx={[title14, { display: "flex", textDecoration: "none" }]}
              >
                {" "}
                Açıklama:{" "}
                <Typography sx={text14wLeft}>
                  {" "}
                  {company?.description || "Yok"}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default TesisDetailModal;
