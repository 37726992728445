import { Helmet } from "react-helmet-async";
import { MRT_Localization_TR } from "material-react-table/locales/tr";
import { useLocation, useNavigate } from "react-router-dom";

// @mui
import { styled } from "@mui/material/styles";
import { Container, TextField, Typography, Box, Snackbar } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { MuiOtpInput } from "mui-one-time-password-input";

import Logo from "../../../components/logo";

// hooks
import useResponsive from "../../../hooks/useResponsive";
import VerifyIcon from "src/components/MyIcons/LoginIcons/VerifyIcon";
import React from "react";
import MuiAlert from "@mui/material/Alert";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 700,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(4, 0),
}));

// ----------------------------------------------------------------------

export default function VerifyRegister({ route, navigation }) {
  const mdUp = useResponsive("up", "md");
  const [otp, setOtp] = React.useState("");
  const [buttonStatus, setButtonStatus] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(Boolean);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  React.useEffect(() => {
    if (otp.length >= 6) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
    console.log(otp.length);
  }, [otp]);

  React.useEffect(() => {
    setTimeout(() => {
      VerifyMail();
    }, 1100);
  }, []);

  function VerifyMail() {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: localStorage.getItem("email"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://dev.plancim.com/api/auth/verify", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  }

  function VerifyMailCode() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: localStorage.getItem("email"),
      key: otp,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://dev.plancim.com/api/auth/verify", requestOptions)
      .then((response) => {
        if (response.ok) {
          setLoading(false);
          setAlertMessage("E-Posta Doğrulama İşlemi Başarılı.");
          setAlertStatus(true);
          handleAlertClick();

          setTimeout(() => {
            navigate("/");
          }, 1200);

          return response.json();
        } else {
          throw new Error(`HTTP Hata: ${response.status}`);
          setLoading(false);
          setAlertMessage("E-Posta Doğrulama İşlemi  Başarısız.");
          setAlertStatus(false);
          handleAlertClick();
        }
      })
      .then((result) => {
        setLoading(false);
      })
      .catch((error) => {
        console.log("Hata:", error.message);
        setLoading(false);
        setAlertMessage("Kayıt İşlemi Başarısız.");
        setAlertStatus(false);
        handleAlertClick();
      });
  }

  return (
    <>
      <Helmet>
        <title> Kayıt Doğrulama | Plancım</title>
      </Helmet>

      <StyledRoot>
        <StyledContent>
          <Logo sx={{ alignSelf: "center" }} />

          <Container
            maxWidth="sm"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "40vw",
              height: "50vh",
              minWidth: "400px",
              border: "1px solid #F4F6F8",
              borderRadius: "16px",
              backgroundColor: "#F9FAFB",
              webkitBoxShadow: "0px 0px 89px -41px rgba(42,48,66,1)",
              mozBoxShadow: "0px 0px 89px -41px rgba(42,48,66,1)",
              boxShadow: "0px 0px 89px -41px rgba(42,48,66,1)",
              overflow: "hidden",
            }}
          >
            <Box style={{ alignSelf: "center", marginBottom: "2rem" }}>
              <VerifyIcon />
            </Box>
            <Typography
              variant="h6"
              sx={{ mb: 4, color: "text.sabitMavi", textAlign: "center" }}
            >
              E-Posta adresinizi doğrulamak için gelen kodu girin.
            </Typography>

            <MuiOtpInput value={otp} onChange={handleChange} length={6} />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              disabled={buttonStatus}
              variant="contained"
              sx={{ mt: 2 }}
              onClick={() => VerifyMailCode()}
            >
              Onayla
            </LoadingButton>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <LoadingButton
                fullWidth
                size="small"
                type="submit"
                variant="outlined"
                sx={{ mt: 2, alignSelf: "center" }}
                onClick={() => navigate("/")}
              >
                Daha Sonra
              </LoadingButton>
              <LoadingButton
                fullWidth
                size="small"
                type="submit"
                variant="outlined"
                sx={{ mt: 2, alignSelf: "center" }}
                onClick={() => VerifyMail()}
              >
                Tekrar Gönder
              </LoadingButton>
            </Box>
          </Container>
        </StyledContent>
      </StyledRoot>

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </>
  );
}
