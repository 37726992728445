// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { StyledChart } from "./components/chart";

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Button, Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { altbox, alttext } from "./theme/customStyles";
import LeafletMap from "./components/LeafletMap/LeafletMap";

// ----------------------------------------------------------------------

export default function App() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const HandleScrollButtonVisibility = () => {
      window.pageYOffset > 300 ? setShowButton(true) : setShowButton(false);
    };

    window.addEventListener("scroll", HandleScrollButtonVisibility);

    return () => {
      window.removeEventListener("scroll", HandleScrollButtonVisibility);
    };
  });

  const HandleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {}, [showButton]);

  return (
    <ThemeProvider>
      {showButton && (
        <Box
          sx={{
            position: "absolute",
            bottom: 100,
            right: 100,
            webkitBoxShadow: " 10px 10px 30px 0px rgba(173,127,35,1)",
            mozBoxShadow: " 10px 10px 30px 0px rgba(173,127,35,1)",
            boxShadow: " 10px 10px 30px 0px rgba(173,127,35,1)",
          }}
        >
          <Button
            onClick={HandleScrollToTop}
            sx={{
              position: "fixed",
              zIndex: "22",
              opacity: "1",
            }}
          >
            <ArrowCircleUpIcon sx={{ width: "3rem", height: "3rem" }} />
          </Button>
        </Box>
      )}
      <StyledChart />

      <Router />

      <Box sx={altbox}>
        <Typography sx={[alttext]}>Tasarım & Geliştirme CB Yazılım</Typography>
        <Typography sx={[alttext]}> 2024 © Plancim</Typography>
      </Box>
    </ThemeProvider>
  );
}

// 1- typeId ve setType'yi useState ile sayfada tanımla.
// 2- typeId'yi useEffectin bağımlılık kancasına ekle. [typeId]
// 3- Table'ı relative box'ın içerisine al. Eski filter'ı sil
// 4- Yeni filterComponent'i import et. type ne yollacayacaksan belirle.
// 5- FilterComponent'te type'a göre apiye istek at ve setle. (Nasıl setleyeceğin GetJobIds apisinin içerisinde yazılı)
// 6- Tabloda listelemek için kullandığın apinin sonuna typeId'yi istenilen formatta ekle.

// Mui elementlerine style verilen her yerde style'ları sx'e çevirebilirsin.
