import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  actionsBox,
  boxStyle,
  hordvdr,
  imgBoxStyle,
  mainContainer,
  text14383838,
  text183838bold,
} from "src/utils/cssStyles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PageHeader from "src/components/Pageheader/PageHeader";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import {
  pageTitle,
  subTitle,
  title14,
  titleBox,
  titleContainer,
} from "src/theme/customStyles";
import { Helmet } from "react-helmet-async";
import { MRT_Localization_TR } from "material-react-table/locales/tr";
import Iconify from "src/components/iconify";
import AddModal from "./AddModal/AddModal";
import MuiAlert from "@mui/material/Alert";
import DeleteModal from "./DeleteModal/DeleteModal";

function Kullaniciİslemleri(props) {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [addModal, setAddModal] = React.useState(false);

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(Boolean);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    getRoles();
    if (addModal == false) getUsers();

    if (deleteModal == false) getUsers();
  }, [addModal, deleteModal]);

  //********************* API *********************//
  const getUsers = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    // URL'i oluştururken searchText parametresini koşullu olarak ekleyin
    let url = `https://dev.plancim.com/api/user?per_page=10&page=1&sort_by=name&sort_type=ASC`;

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.items);
        console.log(data);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };

  const [roles, setRoles] = React.useState("");

  const getRoles = async () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    // URL'i oluştururken searchText parametresini koşullu olarak ekleyin
    let url = `https://dev.plancim.com/api/role/group?per_page=100&page=1&sort_by=name&sort_type=ASC`;

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setRoles(result.items);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  //********************* API *********************//

  return (
    <Box sx={mainContainer}>
      <LoadingOverlay isOpen={loading} />

      <Helmet Helmet>
        <title> Kullanıcı İşlemleri | Plancım </title>
      </Helmet>

      <PageHeader
        title={"KULLANICI İŞLEMLERİ"}
        subtitle={"Kullanıcı > Kullanıcı İşlemleri"}
      />

      <Modal
        open={addModal}
        onClose={() => setAddModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddModal
          setAlertStatus={setAlertStatus}
          setAlertMessage={setAlertMessage}
          handleAlertClick={handleAlertClick}
          setAddModal={setAddModal}
        />
      </Modal>

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DeleteModal
          setAlertStatus={setAlertStatus}
          setAlertMessage={setAlertMessage}
          handleAlertClick={handleAlertClick}
          setDeleteModal={setDeleteModal}
          selectedId={selectedId}
        />
      </Modal>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        mb={12}
      >
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          size="small"
          onClick={() => setAddModal(true)}
          sx={{
            backgroundColor: "success.main",
            fontSize: { xs: "10px", md: "12px", lg: "14px" },
          }}
        >
          Yeni Kullanıcı
        </Button>
      </Stack>

      {data != [] && (
        <Grid container spacing={3}>
          {data.map((user) => (
            <Grid item xs={12} sm={6} md={3} key={user.id}>
              <Box sx={boxStyle}>
                <Typography sx={[title14, { marginBottom: "24px" }]}>
                  {user?.roleGroupName}
                </Typography>

                <Box style={imgBoxStyle}>
                  <Typography sx={titleBox}>
                    {`${user.profile.name.charAt(
                      0
                    )} ${user.profile.surname.charAt(0)}`}
                  </Typography>
                </Box>
                <Typography
                  sx={text183838bold}
                >{`${user.profile.name} ${user.profile.surname}`}</Typography>
                <Typography sx={text14383838}>{user.email}</Typography>

                <Box sx={hordvdr} />

                <Box sx={actionsBox}>
                  <Tooltip title="Detay">
                    <IconButton>
                      <VisibilityIcon
                        color={"warning.secondary"}
                        style={{ fontSize: "24px" }}
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Düzenle">
                    <IconButton>
                      <EditIcon
                        color={"success"}
                        style={{ fontSize: "24px" }}
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Sil">
                    <IconButton
                      onClick={() => {
                        setSelectedId(user.id);

                        console.log(user.id);
                        setDeleteModal(true);
                      }}
                    >
                      {" "}
                      <DeleteForeverIcon
                        color={"error"}
                        style={{ fontSize: "24px" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Kullaniciİslemleri;
