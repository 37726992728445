import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Snackbar,
  Typography,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import MuiAlert from "@mui/material/Alert";
import { getInfos } from "src/utils/services/services";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(Boolean);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [remember, setSetRemember] = useState(false);
  const [isReadyToLogin, setIsReadyToLogin] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  async function Login() {
    localStorage.setItem("email", email);

    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://dev.plancim.com/api/auth/login/true", requestOptions)
      .then((response) => {
        if (response.ok) {
          setLoading(false);
          setAlertMessage("Giriş Yapılıyor.");
          setAlertStatus(true);
          handleAlertClick();
          return response.json();
        } else {
          setLoading(false);
          setAlertMessage(response.message);
          setAlertStatus(false);
          handleAlertClick();
          return response.json();
        }
      })
      .then((result) => {
        if (result?.auth?.token) {
          navigate("/dashboard/Anasayfa");

          if (remember) {
            localStorage.setItem("email", email);
            localStorage.setItem("password", password);
          }

          localStorage.setItem("token", result.auth.token);
          localStorage.setItem("Organization", result.organizationId);
          localStorage.setItem("UserId", result.userId);

          console.log("Başarılı giriş:", result.status);
          getInfos();
        } else {
          if (result.error == 11) {
            navigate("/registerVerify");
          }
          setLoading(false);
          setAlertMessage(result.message);
          setAlertStatus(false);
          handleAlertClick();
        }

        setLoading(false);
      })
      .catch((error) => {
        setAlertMessage("Giriş Başarısız.");
        setAlertStatus(false);
        handleAlertClick();
        setLoading(false);
      });
  }

  const handleCheckboxChange = (event) => {
    localStorage.setItem("rememberMe", event.target.checked);

    setSetRemember(event.target.checked);
  };

  useEffect(() => {
    async function rememberLogin() {
      const x = await localStorage.getItem("rememberMe");
      const rememberName = await localStorage.getItem("email");
      const rememberPass = await localStorage.getItem("password");

      setEmail(rememberName || "");
      setPassword(rememberPass || "");

      setLoading(true);
      if (x) {
        setIsReadyToLogin(true);
      }
      setLoading(false);
    }

    rememberLogin();
  }, []);

  useEffect(() => {
    // Only attempt login if both email and password are set
    if (isReadyToLogin && email && password) {
      Login();
    }
  }, [isReadyToLogin, email, password]);

  return (
    <>
      <LoadingOverlay isOpen={loading} />

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>

      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email adresi"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        <TextField
          name="password"
          label="Parola"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Typography variant="subtitle2">
          <Checkbox
            checked={remember}
            onChange={handleCheckboxChange}
            id="rememberMeCheckbox"
          />
          <label htmlFor="rememberMeCheckbox" style={{ cursor: "pointer" }}>
            Beni Hatırla
          </label>
        </Typography>
        <Link variant="subtitle2" underline="hover" href="/ForgetPassword">
          Şifrenizi mi unuttunuz ?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={Login}
      >
        Giriş
      </LoadingButton>
    </>
  );
}
