import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import { MRT_Localization_TR } from "material-react-table/locales/tr";

// @mui
import { styled } from "@mui/material/styles";
import { Container, TextField, Typography, Box } from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { MuiOtpInput } from "mui-one-time-password-input";

// Icons
import TwoFactorIcon from "../../../components/MyIcons/LoginIcons/TwoFactor";
import Logo from "../../../components/logo";

// hooks
import useResponsive from "../../../hooks/useResponsive";

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 700,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(4, 0),
}));

// ----------------------------------------------------------------------

export default function VerifyLogin() {
  const navigate = useNavigate();
  const mdUp = useResponsive("up", "md");
  const [otp, setOtp] = React.useState("");
  const [buttonStatus, setButtonStatus] = React.useState(true);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  React.useEffect(() => {
    if (otp.length >= 6) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
    console.log(otp.length);
  }, [otp]);
  return (
    <>
      <Helmet>
        <title> Giriş Doğrulama | Plancım</title>
      </Helmet>

      <StyledRoot>
        <StyledContent>
          <Logo sx={{ alignSelf: "center" }} />
          <Container
            maxWidth="sm"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "40vw",
              height: "50vh",
              minWidth: "400px",
              border: "1px solid #F4F6F8",
              borderRadius: "16px",
              backgroundColor: "#F9FAFB",
              webkitBoxShadow: "0px 0px 89px -41px rgba(42,48,66,1)",
              mozBoxShadow: "0px 0px 89px -41px rgba(42,48,66,1)",
              boxShadow: "0px 0px 89px -41px rgba(42,48,66,1)",
              overflow: "hidden",
            }}
          >
            <Box style={{ alignSelf: "center", marginBottom: "2rem" }}>
              <TwoFactorIcon />
            </Box>
            <Typography
              variant="h6"
              sx={{ mb: 4, color: "text.sabitMavi", textAlign: "center" }}
            >
              Çift Taraflı Doğrulama için "Email adresinize" kod gönderdik.
            </Typography>

            <MuiOtpInput value={otp} onChange={handleChange} length={6} />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={buttonStatus}
              sx={{ mt: 2 }}
              onClick={() => navigate("/dashboard/Anasayfa")}
            >
              Onayla
            </LoadingButton>
          </Container>
        </StyledContent>
      </StyledRoot>
    </>
  );
}
