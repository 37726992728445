import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import {
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import { dvdrfull } from "src/theme/customStyles";
import { getJobIds, handleScroll } from "src/utils/helpers/GetJobIds";
import CloseIcon from "@mui/icons-material/Close";

export default function FilterComponent({ typeId, setTypeId, type }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  React.useEffect(() => {
    if (type == "Meslek") {
      getJobIds(setData, page);
    } else if (type == "donanim") {
      // getDonanim(setData, page);
    } else if (type == "Bolge") {
      // getBolgeler(setData,page)
    }
  }, [page]);

  const handleScroll = (event) => {
    const isBottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;

    if (isBottom) {
      console.log("Reached the bottom");

      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: 24,
        left: 360,
        zIndex: 44,
        paddingTop: "2px",
      }}
    >
      <Button
        sx={{
          color: "primary.main",
          marginRight: "1rem",
          textTransform: "none",
        }}
        id="basic-button"
        endIcon={<FilterAltIcon />}
        onClick={handleClick}
      >
        Filtrele
      </Button>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        disablePortal
        sx={{
          backgroundColor: "#f9f9f9",
          borderRadius: "12px",
          justifyContent: "center",
          alignItems: "center",
          display: "column",
          flexDirection: "row",
          width: "472px",
          maxWidth: "472px",
          height: "100px",
          border: "1px solid lightgray",
        }}
      >
        <IconButton
          sx={{
            alignSelf: "flex-end",
            marginLeft: "auto",
            justifySelf: "flex-end",
            display: "flex",
          }}
          color="black"
          onClick={handleClick}
        >
          <CloseIcon sx={{ fontSize: "16px" }} />
        </IconButton>
        <Box sx={[dvdrfull, { margin: 0, marginBottom: "12px" }]} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography sx={{ marginLeft: "24px" }}>Nerede</Typography>

          <TextField
            id="outlined-select-job"
            select
            label={`${type} Seçiniz`}
            value={typeId}
            fullWidth
            onChange={(event) => {
              setTypeId(event.target.value);
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  onScroll: handleScroll,
                },
              },
              sx: { fontSize: "12px" }, // This sets the font size for the selected value
            }}
            size="small"
            sx={{ width: "128px", marginLeft: "16px", fontSize: "12px" }}
            InputLabelProps={{
              sx: { fontSize: "12px" }, // This sets the font size for the label
            }}
          >
            {data?.map((res) => (
              <MenuItem key={res.id} value={res.id} sx={{ fontSize: "12px" }}>
                {res.name}
              </MenuItem>
            ))}
          </TextField>

          {typeId != "" && (
            <Button
              onClick={() => {
                setTypeId("");
                setTimeout(() => {
                  handleClick();
                }, 1000);
              }}
              sx={{
                backgroundColor: "success.main",
                fontSize: { xs: "10px", md: "12px", lg: "12px" },
                color: "white",
                marginLeft: "auto",
                marginRight: "24px",
                textTransform: "none",
              }}
              size="small"
            >
              Temizle
            </Button>
          )}
        </Box>
      </Popper>
    </Box>
  );
}
