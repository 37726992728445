import * as React from 'react';

const TwoFactorIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={120} height={120} fill="#2A3042" viewBox="0 0 32 32" {...props}>
    <path d="m11 23.18-2-2.001-1.411 1.41L11 26l6-6-1.41-1.41L11 23.18zM28 30h-4v-2h4V16h-4V8a4.005 4.005 0 0 0-4-4V2a6.007 6.007 0 0 1 6 6v6h2a2.002 2.002 0 0 1 2 2v12a2.002 2.002 0 0 1-2 2Z" />
    <path d="M20 14h-2V8A6 6 0 0 0 6 8v6H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V16a2 2 0 0 0-2-2ZM8 8a4 4 0 0 1 8 0v6H8Zm12 20H4V16h16Z" />
    <path
      d="M0 0h32v32H0z"
      data-name="&lt;Transparent Rectangle&gt;"
      style={{
        fill: 'none',
      }}
    />
  </svg>
);
export default TwoFactorIcon;
