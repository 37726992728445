import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialReactTable from "material-react-table";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import { Helmet } from "react-helmet-async";
import { MRT_Localization_TR } from "material-react-table/locales/tr";
import Iconify from "src/components/iconify";
import uuid from "react-uuid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import MuiAlert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LeftIcon from "src/assetsIcon/leftIcon";
import {
  dvdr50,
  inPag,
  pagIns,
  pageTitle,
  paginationCont,
  renderTopToolbarCustomActionsCont,
  subTitle,
  titleContainer,
  tlbarins,
  tolbr,
} from "src/theme/customStyles";
import { mainContainer, modalStyleNewest } from "src/utils/cssStyles";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PageHeader from "src/components/Pageheader/PageHeader";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

const JobsTypePage = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const navigate = useNavigate();

  const [idToModal, setIdToModal] = React.useState("");
  const [nameToModal, setNameToModal] = React.useState("");
  const [description, setDescription] = React.useState(null);
  const [descriptionToModal, setDescriptionToModal] = React.useState(null);
  const [veriType, setVeriType] = React.useState(null);

  const [deleteModal, setDeleteModal] = useState(false);

  const [addUserIndicator, setAddUserIndicator] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdUpdate, setSelectedIdUpdate] = useState(null);

  const [modalType, setModalType] = useState("");

  React.useEffect(() => {
    getFacilities();

    if (open == false) {
      setName("");
      setDescription("");
      setSelectedIdUpdate();
      setModalType("");
    }
  }, [addUserIndicator, open, deleteModal]);

  React.useEffect(() => {
    if (deleteModal == false) {
      setSelectedId(null);
    }
  }, [deleteModal]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getFacilities = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    // URL'i oluştururken searchText parametresini koşullu olarak ekleyin
    let url = `https://dev.plancim.com/api/job/type?per_page=10&page=${getAllPage}&sort_by=${sortingType}&sort_type=ASC`;
    if (search && search.trim() !== "") {
      url += `&search_text=${search}`;
    }

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode == 401) {
          navigate("/401");
        }
        var endpointsArr = [];

        setTotalPage(result.pagination.totalPage);

        setData(result.items);
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setLoading(false);
      });
  };

  const addMeslek = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://dev.plancim.com/api/job/type/${uuid()}`, requestOptions)
      .then((response) => {
        response.json();
        if (response.ok) {
          console.log(response);
          setAlertStatus(true);
          setAlertMessage("Meslek türü başarıyla eklendi.");
          handleAlertClick();
        } else {
          setAlertMessage(response.message);
          setAlertStatus(false);
          handleAlertClick();
        }
      })
      .then((result) => {
        setAddUserIndicator(!addUserIndicator);
        setOpen(false);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };

  const deleteAbonelik = async () => {
    setLoading(true);

    var myHeaders = new Headers();

    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`https://dev.plancim.com/api/job/type/${selectedId}`, requestOptions)
      .then((response) => {
        response.json();
        if (response.ok) {
          setAlertStatus(true);
          setAlertMessage("Meslek silindi");
          handleAlertClick();
        } else {
          setAlertMessage("Meslek türü değiştirilemez!");
          setAlertStatus(false);
          handleAlertClick();
        }
      })
      .then((result) => {
        setAddUserIndicator(!addUserIndicator);
        setOpen(false);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
    setDeleteModal(false);
    setSelectedId(null);
    setLoading(false);
  };

  const getAbonelikById = async (id) => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`https://dev.plancim.com/api/job/type/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setName(result.name);
        setDescription(result.description);

        setOpen(true);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };

  const updateAbonelikById = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
      description: description,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `https://dev.plancim.com/api/job/type/${selectedIdUpdate}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          console.log(response);
        }
        return response.json();
      })
      .then((result) => {
        if (result.statusCode == 400) {
          setAlertMessage(result.message);
          setAlertStatus(false);
          handleAlertClick();
        } else {
          setAlertMessage("İşlem Başarılı");
          setAlertStatus(true);
          handleAlertClick();
        }

        setOpen(false);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Yazılımcı",
        size: 120,
      },
      {
        accessorKey: "numberOfJob",
        header: "Meslek Sayısı",
        size: 120,
      },
    ],
    []
  );

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(Boolean);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const style = {
    position: "absolute",
    transform: {
      xs: "translate(5%, 30%)",
      md: "translate(100%, 30%)",
      lg: "translate(200%, 30%)",
    },
    width: "373px",
    height: "400px",
    bgcolor: "background.paper",
    border: "1px solid #181e2e",
    boxShadow: 24,
    p: 4,
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    borderRadius: 4,
  };

  // ARAMA //
  const [search, setSearch] = useState("");

  const searchTimeout = useRef(null);

  const handleSearch = (value) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      getFacilities();
    }, 1000);
  };
  // ARAMA //

  // Sıralama //
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const openSort = Boolean(anchorElSort);
  const [sortingType, setSortingType] = useState("name");

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleCloseSort = (item) => {
    setAnchorElSort(null);
    setSortingType(item);
  };

  // Sıralama //

  // Pagination Component //

  const [totalPage, setTotalPage] = useState(1);
  const [getAllPage, setGetAllPage] = useState(1);

  useEffect(() => {
    getFacilities();
  }, [getAllPage]);

  const handleChange = (event) => {
    setGetAllPage(event.target.value);
  };
  //

  return (
    <Box style={mainContainer}>
      <LoadingOverlay isOpen={loading} />

      <Helmet Helmet>
        <title> Meslek Türü İşlemleri | Plancım </title>
      </Helmet>

      <PageHeader
        title={"MESLEK TÜRÜ İŞLEMLERİ"}
        subtitle={"Kadro / Personel > Meslek Türü İşlemleri"}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyleNewest}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                marginBottom: "24px",
                width: "100px",
                height: "100px",
                backgroundColor: "#F4F6F8",
                borderRadius: 999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PersonAddIcon color="success" sx={{ fontSize: "64px" }} />
            </Box>
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              sx={{ color: "primary.main", textAlign: "center" }}
            >
              Yeni Meslek Türü Ekle
            </Typography>

            <Box sx={[dvdr50]} />
          </Box>

          <TextField
            name="İsim*"
            label="İsim*"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            sx={{ marginBottom: "1rem" }}
          />

          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              if (name == "") {
                setAlertMessage("İsim boş girilemez.");
                setAlertStatus(false);
                handleAlertClick();
              } else {
                if (modalType == "edit") {
                  updateAbonelikById();
                } else {
                  addMeslek();
                }
              }
            }}
            color="success"
            sx={{ color: "white", marginTop: "1rem" }}
          >
            {modalType == "edit" ? (
              <span>Güncelle</span>
            ) : (
              <span>Yeni Meslek Türü Ekle</span>
            )}
          </Button>

          <Typography
            sx={{
              color: "grey[400]",
              fontSize: 14,
              alignSelf: "center",
              marginTop: "0.5rem",
            }}
          >
            İsim boş girilemez.
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={[
            style,
            {
              width: "%35",
              height: "30%",
              top: "25%",
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Box
            style={{
              marginBottom: "24px",
              width: "100px",
              height: "100px",
              backgroundColor: "#F4F6F8",
              borderRadius: 999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DeleteForeverIcon color="error" sx={{ fontSize: "64px" }} />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h3"
            sx={{
              color: "primary.main",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            Aboneliği silmek istediğinize emin misiniz ?
          </Typography>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="success"
              sx={{
                color: "white",
                marginRight: "1rem",
                fontSize: 16,
                borderRadius: 16,
                width: "120px",
              }}
              endIcon={<CheckIcon />}
              onClick={() => deleteAbonelik()}
            >
              Evet
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                color: "error",
                marginRight: "1rem",
                fontSize: 16,
                borderRadius: 16,
                width: "120px",
              }}
              endIcon={<CloseIcon />}
              onClick={() => setDeleteModal(false)}
            >
              Vazgeç
            </Button>
          </Box>
        </Box>
      </Modal>

      <MaterialReactTable
        localization={MRT_Localization_TR}
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableRowSelection
        muiTableBodyCellProps={{
          sx: {
            color: "primary.main",
            fontWeight: "600",
            p: "2px 16px",
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            color: "primary.main",
            letterSpacing: "1px",
            fontWeight: "600",
            opacity: "0.8",
          },
        }}
        positionToolbarAlertBanner="top"
        positionActionsColumn="last"
        enableRowActions
        enablePagination={false}
        renderBottomToolbar={({ table, index }) => (
          <Box sx={tolbr}>
            <Box sx={inPag}>
              <Box sx={pagIns}>
                <IconButton
                  onClick={() => {
                    if (getAllPage > 1) {
                      setGetAllPage(getAllPage - 1);
                    }
                  }}
                >
                  <LeftIcon />
                </IconButton>
                <IconButton
                  sx={{ transform: "scaleX(-1) " }}
                  onClick={() => {
                    setGetAllPage(getAllPage + 1);
                  }}
                >
                  <LeftIcon />
                </IconButton>
                <FormControl
                  size="small"
                  sx={{ width: "75px", minWidth: "50px" }}
                >
                  <InputLabel id="demo-simple-select-label">Sayfa</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={getAllPage}
                    label="Sayfa"
                    onChange={handleChange}
                  >
                    {Array.from({ length: totalPage }, (_, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        )}
        renderRowActions={({ row, table }) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              flexDirection: "row",
              gap: "1rem",
              width: "100px",
            }}
          >
            <Tooltip title="Düzenle">
              <IconButton
                onClick={() => {
                  if (row.original.isMain) {
                    setAlertMessage("Meslek Türü değiştirilemez!");
                    setAlertStatus(false);
                    handleAlertClick();
                  } else {
                    getAbonelikById(row.original.id);
                    setModalType("edit");
                    setSelectedIdUpdate(row.original.id);
                  }
                }}
              >
                <EditIcon color={"success"} style={{ fontSize: "24px" }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Sil">
              <IconButton
                onClick={() => {
                  if (row.original.isMain) {
                    setAlertMessage("Meslek Türü silinemez!");
                    setAlertStatus(false);
                    handleAlertClick();
                  } else {
                    setDeleteModal(true);
                    setSelectedId(row.original.id);
                  }
                }}
              >
                <DeleteForeverIcon
                  color={"error"}
                  style={{ fontSize: "24px" }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={({ table }) => (
          <Box style={renderTopToolbarCustomActionsCont}>
            <TextField
              name="Arama"
              label="Arama.."
              value={search}
              onKeyDown={handleSearch}
              onChange={(e) => {
                const { value } = e.target;
                setSearch(value);
                handleSearch(value);
              }}
              sx={{
                marginRight: "12px",
              }}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              id="basic-button"
              aria-controls={openSort ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openSort ? "true" : undefined}
              onClick={handleClickSort}
              sx={{ color: "primary.main", marginRight: "1rem" }}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Sırala
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElSort}
              open={openSort}
              onClose={() => handleCloseSort(sortingType)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleCloseSort("name")}>
                İsime Göre
              </MenuItem>
            </Menu>
            <Button
              sx={{ color: "primary.main", marginRight: "1rem" }}
              id="basic-button"
              endIcon={<FilterAltIcon />}
            >
              Filtrele
            </Button>
          </Box>
        )}
        renderToolbarInternalActions={({ table }) => (
          <Box sx={paginationCont}>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleOpen}
              size="small"
              sx={{
                backgroundColor: "success.main",
                fontSize: { xs: "10px", md: "12px", lg: "14px" },
              }}
            >
              Yeni Meslek Türü
            </Button>
          </Box>
        )}
      />

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default JobsTypePage;
