import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Snackbar,
  Typography,
  Box,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";

// components
import Iconify from "../../../components/iconify";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isDigit, setIsDigit] = useState(false);
  const [isSpecialChar, setIsSpecialChar] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(Boolean);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    if (confirmPassword.length >= 8) {
      setIsLengthValid(true);
    } else {
      setIsLengthValid(false);
    }

    if (/[a-z]/.test(confirmPassword)) {
      setIsLowerCase(true);
    } else {
      setIsLowerCase(false);
    }

    if (/\d/.test(confirmPassword)) {
      setIsDigit(true);
    } else {
      setIsDigit(false);
    }

    if (/[!@#$%^&*()_+.]/.test(confirmPassword)) {
      setIsSpecialChar(true);
    } else {
      setIsSpecialChar(false);
    }

    if (/[A-Z]/.test(confirmPassword)) {
      setIsUpperCase(true);
    } else {
      setIsUpperCase(false);
    }

    if (password === confirmPassword) {
      setIsPasswordMatch(true);
    } else {
      setIsPasswordMatch(false);
    }

    if (
      isUpperCase &&
      isLowerCase &&
      isDigit &&
      isSpecialChar &&
      isLengthValid &&
      isPasswordMatch &&
      name != "" &&
      surname != "" &&
      email != "" &&
      phone != ""
    ) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
  }, [name, surname, email, phone, password, confirmPassword, isPasswordMatch]);

  function Register() {
    localStorage.setItem("email", email);

    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      user: {
        email: email,
        phone: {
          code: "+90",
          number: phone,
        },
        password: password,
        profile: {
          name: name,
          surname: surname,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://dev.plancim.com/api/auth/register", requestOptions)
      .then((response) => {
        if (response.ok) {
          navigate("/registerVerify");
          setLoading(false);
          setAlertMessage("Kayıt İşlemi Başarılı.");
          setAlertStatus(true);
          handleAlertClick();
          return response.json();
        } else {
          setLoading(false);
          setAlertMessage("Kayıt İşlemi Başarısız.");
          setAlertStatus(false);
          handleAlertClick();
        }
      })
      .then((result) => {
        console.log("Başarılı giriş:", result);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Hata:", error.message);
        setLoading(false);
        setAlertMessage("Kayıt İşlemi Başarısız.");
        setAlertStatus(false);
        handleAlertClick();
      });
  }

  const handleTelefonNumarasiChange = (event) => {
    const value = event.target.value;

    // Sadece rakam kabul et
    const sanitizedValue = value.replace(/\D/g, "");

    // 10 haneden fazla değilse ve 0 ile başlamıyorsa değeri güncelle
    if (sanitizedValue.length <= 10 && sanitizedValue[0] !== "0") {
      setPhone(sanitizedValue);
    }
  };

  // Telefon numarasını formatla (3, 3, 4)
  const formatTelefonNumarasi = (value) => {
    const formattedValue = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
    return formattedValue.trim();
  };

  return (
    <>
      <LoadingOverlay isOpen={loading} />

      <Stack spacing={3}>
        <TextField
          name="ad"
          label="Ad"
          sx={{ input: { color: "#2A3042" } }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <TextField
          name="soyad"
          label="Soyad"
          sx={{ input: { color: "#2A3042" } }}
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
        />

        <TextField
          name="email"
          label="Email adresi"
          sx={{ input: { color: "#2A3042" } }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          name="telefon"
          label="Telefon Numarası"
          placeholder="Lütfen başına +90 yazmadan telefon numaranızı girin."
          value={formatTelefonNumarasi(phone)}
          onChange={handleTelefonNumarasiChange}
          inputProps={{ maxLength: 10 }}
          sx={{ input: { color: "#2A3042" }, borderColor: "red" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+90</InputAdornment>
            ),
          }}
        />

        <TextField
          name="confirmPassword"
          label="Parolayı "
          type={showPassword ? "text" : "password"}
          sx={{ input: { color: "#2A3042" } }}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          name="confirmPassword"
          label="Parolayı Onayla"
          type={showPassword ? "text" : "password"}
          sx={{ input: { color: "#2A3042" } }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
          }}
        >
          <Typography
            style={{
              color: isUpperCase ? "green" : "red",
              fontSize: 12,
              marginBottom: "0.5rem",
            }}
          >
            • Bir büyük harf içermelidir
          </Typography>
          <Typography
            style={{
              color: isLowerCase ? "green" : "red",
              fontSize: 12,
              marginBottom: "0.5rem",
            }}
          >
            • Bir küçük harf içermelidir
          </Typography>
          <Typography
            style={{
              color: isDigit ? "green" : "red",
              fontSize: 12,
              marginBottom: "0.5rem",
            }}
          >
            • Bir rakam içermelidir
          </Typography>
          <Typography
            style={{
              color: isSpecialChar ? "green" : "red",
              fontSize: 12,
              marginBottom: "0.5rem",
            }}
          >
            • Bir özel karakter içermelidir (.,!,*,+,^,(,),%)
          </Typography>
          <Typography
            style={{ color: isLengthValid ? "green" : "red", fontSize: 12 }}
          >
            • En az 8 karakter içermelidir
          </Typography>
        </Box>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={Register}
        sx={{ my: 2 }}
        disabled={buttonStatus}
      >
        Kayıt Ol
      </LoadingButton>

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </>
  );
}
