import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialReactTable from "material-react-table";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import { Helmet } from "react-helmet-async";
import { MRT_Localization_TR } from "material-react-table/locales/tr";

import Iconify from "src/components/iconify";
import uuid from "react-uuid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import MuiAlert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FacilityDetailModal from "src/components/YobComponents/FacilityDetailModal/FacilityDetailModal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LeftIcon from "src/assetsIcon/leftIcon";
import {
  inPag,
  pagIns,
  pageTitle,
  paginationCont,
  renderTopToolbarCustomActionsCont,
  subTitle,
  titleContainer,
  tlbarins,
  tolbr,
} from "src/theme/customStyles";
import { mainContainer } from "src/utils/cssStyles";
import MaterialTypeDetail from "src/components/YobComponents/MaterialDetailModal/MaterialTypeDetail";
import AddIcon from "@mui/icons-material/Add";
import PageHeader from "src/components/Pageheader/PageHeader";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";

const MalzemeAltBaslik = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [materialTitleId, setMaterialTitleId] = React.useState("");

  const [description, setDescription] = React.useState(null);
  const [adress, setAdress] = React.useState(null);
  const [type, setType] = React.useState(null);

  const [materialId, setMaterialId] = React.useState(null);
  const [allMaterials, setAllMaterials] = React.useState([]);

  const [idToModal, setIdToModal] = React.useState("");
  const [nameToModal, setNameToModal] = React.useState("");
  const [descriptionToModal, setDescriptionToModal] = React.useState(null);
  const [veriType, setVeriType] = React.useState(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);

  const [addUserIndicator, setAddUserIndicator] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdUpdate, setSelectedIdUpdate] = useState(null);

  const [modalType, setModalType] = useState("");

  React.useEffect(() => {
    getMaterial();
    if (open == false) {
      setName("");
      setDescription(null);
      setType(null);
      setAdress(null);
      setMaterialId(null);
      setSelectedIdUpdate();
      setModalType("");
      setMaterialTitleId("");
    }
  }, [addUserIndicator, open, detailModal, deleteModal]);

  React.useEffect(() => {
    if (deleteModal == false) {
      setSelectedId(null);
    }
  }, [deleteModal]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //*********** API  ************/
  const getMaterial = async () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    // URL'i oluştururken searchText parametresini koşullu olarak ekleyin
    let url = `https://dev.plancim.com/api/material/type?per_page=10&page=${getAllPage}&sort_by=${sortingType}&sort_type=ASC`;
    if (search && search.trim() !== "") {
      url += `&search_text=${search}`;
    }

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        var endpointsArr = [];
        console.log(result);

        setTotalPage(result.pagination.totalPage);

        for (let i = 0; i < result.items.length; i++) {
          const user = result.items[i];
          endpointsArr.push({
            ID: user.id,
            Name: user.name,
            Statik: user.isMain ? "Statik Veri" : "Dinamik Veri",
            veriType: user.isMain,
            numberOfMaterial: user.numberOfMaterial,
            materialTitle: user.materialTitle.name,
          });
        }

        setData(endpointsArr);
      })
      .catch((error) => console.log("error", error));
    setLoading(false);
  };

  const addMaterial = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
      materialTitleId: materialTitleId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://dev.plancim.com/api/material/type/${uuid()}`, requestOptions)
      .then((response) => {
        response.json();
        if (response.ok) {
          console.log(response);
          setAlertStatus(true);

          setAlertMessage("malzeme başarıyla eklendi.");
          handleAlertClick();
        } else {
          setAlertMessage(response.message);
          setAlertStatus(false);
          handleAlertClick();
        }
      })
      .then((result) => {
        setAddUserIndicator(!addUserIndicator);
        setOpen(false);
        console.log(result);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };

  const deleteMaterial = async () => {
    setLoading(true);

    console.log(selectedId);
    console.log("selectedId");

    var myHeaders = new Headers();

    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `https://dev.plancim.com/api/material/type/${selectedId}`,
      requestOptions
    )
      .then((response) => {
        response.json();
        if (response.ok) {
          setAlertStatus(true);
          setAlertMessage("Malzeme silindi");
          handleAlertClick();
        } else {
          setAlertMessage("Sabit malzeme değiştirilemez!");
          setAlertStatus(false);
          handleAlertClick();
        }
      })
      .then((result) => {
        setAddUserIndicator(!addUserIndicator);
        setOpen(false);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
    setDeleteModal(false);
    setSelectedId(null);
    setLoading(false);
  };

  const getMaterialById = async (id) => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`https://dev.plancim.com/api/material/type/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setName(result.name);
        setDescription(result.description);

        setOpen(true);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };

  const updateMaterialById = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
      description: description,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `https://dev.plancim.com/api/material/type/${selectedIdUpdate}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          console.log(response);
        }
        return response.json();
      })
      .then((result) => {
        if (result.statusCode == 400) {
          setAlertMessage(result.message);
          setAlertStatus(false);
          handleAlertClick();
        } else {
          setAlertMessage("İşlem Başarılı");
          setAlertStatus(true);
          handleAlertClick();
        }

        setOpen(false);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };

  //*********** API  ************/

  const columns = useMemo(
    () => [
      {
        accessorKey: "Name",
        header: "İsim",
        size: 120,
      },

      {
        accessorKey: "materialTitle",
        header: "Malzeme Başlığı",
        size: 120,
      },
      {
        accessorKey: "numberOfMaterial",
        header: "Malzeme Sayısı",
        size: 120,
      },

      {
        accessorKey: "Statik",
        header: "Veri Tipi",
        size: 120,
      },
    ],
    []
  );

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
    filename: "User_Transactions",
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(Boolean);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const style = {
    position: "absolute",
    transform: {
      xs: "translate(5%, 35%)",
      md: "translate(100%, 35%)",
      lg: "translate(200%, 35%)",
    },
    width: "373px",
    height: "500px",
    bgcolor: "background.paper",
    border: "1px solid #181e2e",
    boxShadow: 24,
    p: 4,
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    borderRadius: 4,
  };

  // ARAMA //
  const [search, setSearch] = useState("");

  const searchTimeout = useRef(null);

  const handleSearch = (value) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      getMaterial();
    }, 1000);
  };
  // ARAMA //

  // Sıralama //
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const openSort = Boolean(anchorElSort);
  const [sortingType, setSortingType] = useState("name");

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleCloseSort = (item) => {
    setAnchorElSort(null);
    setSortingType(item);
  };

  // Sıralama //

  // Pagination Component //

  const [totalPage, setTotalPage] = useState(1);
  const [getAllPage, setGetAllPage] = useState(1);
  useEffect(() => {
    getMaterial();
  }, [getAllPage]);

  const handleChange = (event) => {
    setGetAllPage(event.target.value);
  };
  //

  React.useEffect(() => {
    getMaterials(page);
  }, [page2]);

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop >
      event.target.clientHeight;

    console.log(bottom);

    if (bottom) {
      setPage2((prevPage) => prevPage + 1); // Sayfayı arttırarak yeni veri yüklemeyi tetikle
    }
  };

  // get job ID //
  const getMaterials = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `https://dev.plancim.com/api/material/title?per_page=45&page=${page2}&sort_by=name&sort_type=ASC`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setAllMaterials((prevJobIds) => [...prevJobIds, ...result.items]);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };
  // get job ID //

  return (
    <Box style={mainContainer}>
      <LoadingOverlay isOpen={loading} />

      <Helmet Helmet>
        <title> Malzeme Alt Başlık | Plancım </title>
      </Helmet>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                marginBottom: "24px",
                width: "100px",
                height: "100px",
                backgroundColor: "#F4F6F8",
                borderRadius: 999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AddIcon color="success" sx={{ fontSize: "64px" }} />
            </Box>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h5"
              sx={{ color: "primary.main", fontWeight: 500 }}
            >
              Yeni Malzeme Ekle
            </Typography>

            <Box
              style={{
                width: "50%",
                height: "0.01rem",
                backgroundColor: "#181e2e",
                opacity: 0.2,
                marginTop: "0.5rem",
                borderRadius: 16,
                marginBottom: "1rem",
              }}
            />
          </Box>

          <TextField
            name="İsim*"
            label="İsim*"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            style={{ marginBottom: "1rem" }}
          />

          <TextField
            id="outlined-select-job"
            select
            label="Meslek Seçiniz*"
            value={materialTitleId}
            onChange={(e) => {
              setMaterialTitleId(e.target.value);
            }}
            // SelectProps={{
            //   MenuProps: {
            //     PaperProps: {
            //       onScroll: handleScroll,
            //     },
            //   },
            // }}
          >
            {allMaterials?.map((res) => (
              <MenuItem key={res.id} value={res.id}>
                {res.name}
              </MenuItem>
            ))}
          </TextField>

          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              if (modalType == "edit") {
                updateMaterialById();
              } else {
                if (name == "" || materialTitleId == "") {
                  setAlertMessage("İsim ve ya Malzeme boş girilemez.");
                  setAlertStatus(false);
                  handleAlertClick();
                } else {
                  addMaterial();
                }
              }
            }}
            color="success"
            style={{ color: "white", marginTop: "1rem" }}
          >
            {modalType == "edit" ? (
              <span>Güncelle</span>
            ) : (
              <span>Yeni Malzeme Ekle</span>
            )}
          </Button>

          <Typography
            sx={{
              color: "grey[400]",
              fontSize: 14,
              alignSelf: "center",
              marginTop: "0.5rem",
            }}
          >
            İsim ve ya Malzeme boş girilemez.{" "}
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={[
            style,
            {
              width: "%30",
              height: "30%",
              top: "25%",
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Box
            style={{
              marginBottom: "24px",
              width: "100px",
              height: "100px",
              backgroundColor: "#F4F6F8",
              borderRadius: 999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DeleteForeverIcon color="error" sx={{ fontSize: "64px" }} />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h3"
            sx={{
              color: "primary.main",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            Malzemeyi silmek istediğinize emin misiniz ?
          </Typography>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="success"
              sx={{
                color: "white",
                marginRight: "1rem",
                fontSize: 16,
                borderRadius: 16,
                width: "120px",
              }}
              endIcon={<CheckIcon />}
              onClick={() => deleteMaterial()}
            >
              Evet
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                color: "error",
                marginRight: "1rem",
                fontSize: 16,
                borderRadius: 16,
                width: "120px",
              }}
              endIcon={<CloseIcon />}
              onClick={() => setDeleteModal(false)}
            >
              Vazgeç
            </Button>
          </Box>
        </Box>
      </Modal>

      <PageHeader
        title={"MALZEME İŞLEMLERİ"}
        subtitle={"Malzeme > Malzeme Alt Başlık İşlemleri"}
      />

      <MaterialReactTable
        localization={MRT_Localization_TR}
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableRowSelection
        muiTableBodyCellProps={{
          sx: {
            color: "primary.main",
            fontWeight: "600",
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            color: "primary.main",
            letterSpacing: "1px",
            fontWeight: "600",
            opacity: "0.8",
          },
        }}
        positionToolbarAlertBanner="top"
        positionActionsColumn="last"
        enableRowActions
        enablePagination={false}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            setIdToModal(row.original.ID);
            setNameToModal(row.original.materialTitle);
            setDescriptionToModal(row.original.description);
            setVeriType(row.original.veriType);
            setDetailModal(true);
          },
          sx: {
            cursor: "pointer", // Optional: Add a pointer cursor to indicate clickability
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)", // Optional: Add a hover effect
            },
          },
        })}
        renderRowActions={({ row, table }) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              flexDirection: "row",
              gap: "1rem",
              width: "100px",
            }}
          >
            <Tooltip title="Detay">
              <IconButton
                onClick={() => {
                  setIdToModal(row.original.ID);
                  setNameToModal(row.original.materialTitle);
                  setDescriptionToModal(row.original.description);
                  setVeriType(row.original.veriType);
                  setDetailModal(true);
                }}
              >
                <VisibilityIcon
                  color={"warning.secondary"}
                  style={{ fontSize: "24px" }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Düzenle">
              <IconButton
                onClick={() => {
                  if (row.original.veriType) {
                    setAlertMessage("Sabit malzeme değiştirilemez!");
                    setAlertStatus(false);
                    handleAlertClick();
                  } else {
                    getMaterialById(row.original.ID);
                    setModalType("edit");
                    setSelectedIdUpdate(row.original.ID);
                  }
                }}
              >
                <EditIcon color={"success"} style={{ fontSize: "24px" }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Sil">
              <IconButton
                onClick={() => {
                  if (row.original.veriType) {
                    setAlertMessage("Sabit malzeme silinemez!");
                    setAlertStatus(false);
                    handleAlertClick();
                  } else {
                    setDeleteModal(true);
                    setSelectedId(row.original.ID);
                  }
                }}
              >
                <DeleteForeverIcon
                  color={"error"}
                  style={{ fontSize: "24px" }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={({ table }) => (
          <Box style={renderTopToolbarCustomActionsCont}>
            <TextField
              name="Arama"
              label="Arama.."
              value={search}
              onKeyDown={handleSearch}
              onChange={(e) => {
                const { value } = e.target;
                setSearch(value);
                handleSearch(value);
              }}
              sx={{
                marginRight: "12px",
              }}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              id="basic-button"
              aria-controls={openSort ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openSort ? "true" : undefined}
              onClick={handleClickSort}
              sx={{ color: "primary.main", marginRight: "1rem" }}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Sırala
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElSort}
              open={openSort}
              onClose={() => handleCloseSort(sortingType)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleCloseSort("name")}>
                İsime Göre
              </MenuItem>
            </Menu>
            <Button
              sx={{ color: "primary.main", marginRight: "1rem" }}
              id="basic-button"
              endIcon={<FilterAltIcon />}
            >
              Filtrele
            </Button>
          </Box>
        )}
        renderBottomToolbar={({ table, index }) => (
          <Box sx={tolbr}>
            <Box sx={inPag}>
              <Box sx={pagIns}>
                <IconButton
                  onClick={() => {
                    if (getAllPage > 1) {
                      setGetAllPage(getAllPage - 1);
                    }
                  }}
                >
                  <LeftIcon />
                </IconButton>
                <IconButton
                  sx={{ transform: "scaleX(-1) " }}
                  onClick={() => {
                    setGetAllPage(getAllPage + 1);
                  }}
                >
                  <LeftIcon />
                </IconButton>
                <FormControl
                  size="small"
                  sx={{ width: "75px", minWidth: "50px" }}
                >
                  <InputLabel id="demo-simple-select-label">Sayfa</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={getAllPage}
                    label="Sayfa"
                    onChange={handleChange}
                  >
                    {Array.from({ length: totalPage }, (_, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        )}
        renderToolbarInternalActions={({ table }) => (
          <Box sx={paginationCont}>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleOpen}
              size="small"
              sx={{
                backgroundColor: "success.main",
                fontSize: { xs: "10px", md: "12px", lg: "14px" },
              }}
            >
              Yeni Malzeme
            </Button>
          </Box>
        )}
      />

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>

      {detailModal && (
        <MaterialTypeDetail
          setModalVisible={setDetailModal}
          modalVisible={detailModal}
          type={nameToModal}
          id={idToModal}
        />
      )}
    </Box>
  );
};

export default MalzemeAltBaslik;
