import global from "src/Global";

export function getInfos() {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", localStorage.getItem("token"));
  myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
  myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch("https://dev.plancim.com/api/topbar/profile", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      localStorage.setItem("UserName", result.profile.name);
      localStorage.setItem("Surname", result.profile.surname);
      localStorage.setItem("Role", result.profile.role);
    })
    .catch((error) => console.error(error));
}

export const getCities = async (setCities) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", localStorage.getItem("token"));
  myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
  myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  // URL'i oluştururken searchText parametresini koşullu olarak ekleyin
  let url = `https://dev.plancim.com/api/static/city?per_page=81&page=1&sort_by=name&sort_type=ASC`;

  // selectedCity null değilse, URL'e ekliyoruz

  fetch(url, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      setCities(result.items);
      console.log(result);
    })
    .catch((error) => console.log("error", error));
};

export const getDistirct = async (selectedCity, setDistricts) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", localStorage.getItem("token"));
  myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
  myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  // URL'i oluştururken searchText parametresini koşullu olarak ekleyin
  let url = `https://dev.plancim.com/api/static/district?per_page=100&page=1&sort_by=city_name&sort_type=ASC&city_id=${selectedCity}`;
  fetch(url, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      setDistricts(result.items);
    })
    .catch((error) => console.log("error", error));
};
