import { useState } from "react";

import { Helmet } from "react-helmet-async";
import { MRT_Localization_TR } from "material-react-table/locales/tr";
// @mui
import { styled } from "@mui/material/styles";
import { Link, Container, Typography } from "@mui/material";
// hooks
import useResponsive from "../hooks/useResponsive";
// components
import Logo from "../components/logo";
// sections
import { LoginForm } from "../sections/auth/login";
import { RegisterForm } from "../sections/auth/register";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive("up", "md");
  const [status, setStatus] = useState("login");

  const handleChange = () => {
    if (status === "login") {
      setStatus("register");
    } else {
      setStatus("login");
    }
  };

  return (
    <>
      <Helmet>
        <title> Giriş Ekranı | Plancım</title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: "fixed",
            top: { xs: -60, sm: 24, md: 40 },
            left: { xs: 120, sm: 240, md: 120 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography
              variant="h4"
              sx={{ px: 5, mt: 35, mb: 5, color: "text.sabitMavi" }}
            >
              Selam, Hoşgeldin !
            </Typography>
            <img src="/assets/illustrations/loginhg.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: "text.sabitMavi" }}
            >
              {status === "login" ? "Giriş Yap " : "Kayıt Ol"}
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              {status === "login"
                ? "Henüz hesabın yok mu? "
                : " Hesabın var mı ? "}

              <Link
                variant="subtitle2"
                onClick={handleChange}
                style={{ cursor: "pointer" }}
              >
                {status === "login" ? "Hemen kayıt Ol" : "Giriş yap"}
              </Link>
            </Typography>

            {status === "login" ? <LoginForm /> : <RegisterForm />}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
