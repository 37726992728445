import { useEffect, useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
} from "@mui/material";
// mocks_
import account from "../../../_mock/account";
import { useNavigate } from "react-router-dom";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { grey } from "@mui/material/colors";
import zIndex from "@mui/material/styles/zIndex";
import { dvdrDik } from "src/theme/customStyles";
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Anasayfa",
    icon: "eva:home-fill",
  },
  {
    label: "Profil",
    icon: "eva:home-fill",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const [authCode, setAuthCode] = useState(localStorage.getItem("AuthCode"));
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const logOut = () => {
    navigate("/");
    localStorage.removeItem("token");
    localStorage.removeItem("UserId");
    localStorage.removeItem("Organization");

    localStorage.removeItem("email");
    localStorage.removeItem("password");
    localStorage.removeItem("rememberMe");
  };

  useEffect(() => {}, [localStorage.getItem("UserName")]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: 2,
        py: 0.5,
      }}
    >
      <IconButton
        sx={{
          p: 0,
        }}
      >
        <Avatar sx={{ width: "28px", height: "28px" }}>
          <QuestionAnswerIcon
            sx={{ color: "secondary.main", fontSize: "16px" }}
          />
        </Avatar>
      </IconButton>
      <Box sx={dvdrDik} />
      <IconButton
        sx={{
          p: 0,
          marginRight: "0.3rem",
        }}
      >
        <Box
          style={{
            width: "14px",
            height: "14px",
            position: "absolute",
            top: "20px",
            left: "16px",
            zIndex: 44,
            backgroundColor: grey[400],
            borderRadius: "444px",
          }}
        >
          <Typography
            sx={{
              color: "error.dark",
              fontWeigt: "bold",
              fontSize: "11px",
            }}
          >
            1
          </Typography>
        </Box>
        <Avatar sx={{ width: "28px", height: "28px" }}>
          <NotificationsActiveIcon
            sx={{ color: "secondary.main", fontSize: "16px" }}
          />
        </Avatar>
      </IconButton>
      <Box sx={dvdrDik} />
      <Box sx={{ my: 1.5, px: 2 }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{ color: "text.secondary", fontSize: "12px" }}
        >
          {localStorage.getItem("UserName")} {localStorage.getItem("Surname")}{" "}
        </Typography>
        <Typography sx={{ color: "text.primary", fontSize: "12px" }} noWrap>
          {global.packet}
        </Typography>
      </Box>

      <IconButton
        onClick={handleOpen}
        sx={{
          backgroundColor: "red",
          p: 0,
          marginRight: "0.3rem",

          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
            },
          }),
        }}
      >
        <Avatar
          src={account.photoURL}
          alt="photoURL"
          sx={{ color: "secondary.main" }}
        />
      </IconButton>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,

            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => {
                handleClose();

                if (option.label === "Anasayfa") {
                  navigate("/dashboard/Anasayfa");
                } else if (option.label === "Admin Ekle") {
                  navigate("/dashboard/adminekle");
                }
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={() => [handleClose(), logOut()]} sx={{ m: 1 }}>
          Çıkış Yap
        </MenuItem>
      </Popover>
    </Box>
  );
}
