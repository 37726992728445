import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  text14wLeft,
  dvdr50,
  dvdrDik,
  dvdrfull,
  modalStyle,
  newModalStyle,
  subTitle,
  title14,
  title20,
  titleStyle,
} from "src/theme/customStyles";
import CloseIcon from "@mui/icons-material/Close";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import { modalStyleNewest } from "src/utils/cssStyles";

const DetailStaticsCity = ({ modalVisible, setModalVisible, id, type }) => {
  const [cityDetail, setCityDetail] = useState(null);
  useEffect(() => {
    const fetchCityDetail = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("token"));
      myHeaders.append(
        "X-Organization-Id",
        localStorage.getItem("Organization")
      );
      myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `https://dev.plancim.com/api/static/${type}/${id}`,
          requestOptions
        );
        const result = await response.json();
        setCityDetail(result);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCityDetail();
  }, [id]);

  if (!cityDetail) {
    return <LoadingOverlay isOpen={true} />;
  }

  const {
    name,
    coordinate,
    snowLoad,
    earthquake,
    heatZone,
    frostDepth,
    neighboringProvinces,
  } = cityDetail;

  return (
    <Modal
      open={modalVisible}
      onClose={() => setModalVisible(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyleNewest}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography style={titleStyle}>DETAY</Typography>
          <IconButton
            sx={{ alignSelf: "flex-end", marginLeft: "auto" }}
            color="black"
            onClick={() => setModalVisible(false)}
          >
            <CloseIcon sx={{ fontSize: "32px" }} />
          </IconButton>
        </Box>
        <Typography style={subTitle}>{name} Detayları</Typography>

        <Box
          style={{
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          <Typography
            sx={[
              title14,
              {
                alignItems: "center",
                display: "flex",
                textDecoration: "none",
              },
            ]}
          >
            Koordinatlar:{" "}
            <Typography sx={[text14wLeft]}>
              {coordinate.latitude}, {coordinate.longitude}
            </Typography>
          </Typography>

          <Typography
            sx={[title14, { display: "flex", textDecoration: "none" }]}
          >
            {" "}
            Kar Yükü : <Typography sx={text14wLeft}> {snowLoad}</Typography>
          </Typography>
          <Typography
            sx={[title14, { display: "flex", textDecoration: "none" }]}
          >
            {" "}
            Deprem: <Typography sx={text14wLeft}> {earthquake}</Typography>
          </Typography>
        </Box>

        <Box style={{ marginBottom: "24px" }}>
          <Typography sx={[title20]}>Komşu İller</Typography>
          <Box sx={[dvdr50, { marginBottom: "12px" }]} />

          <Box>
            <List
              sx={{
                bgcolor: "background.paper",
                gap: "12px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {neighboringProvinces.map(({ id, name, length }) => (
                <Box key={id} sx={{ display: "flex" }}>
                  <Typography
                    sx={[
                      title14,
                      { textDecoration: "none", marginRight: "6px" },
                    ]}
                  >
                    {name} :{" "}
                  </Typography>{" "}
                  <Typography sx={text14wLeft}> {length}km</Typography>
                </Box>
              ))}
            </List>
          </Box>
        </Box>

        <Box style={{ marginBottom: "24px" }}>
          <Typography sx={[title20, { width: "200px" }]}>
            Sıcaklık Bölgeleri
          </Typography>
          <Box sx={[dvdr50, { marginBottom: "12px" }]} />
          <List
            sx={{
              bgcolor: "background.paper",
            }}
          >
            {Object.entries(heatZone).map(([month, temperature]) => (
              <ListItem key={month}>
                <Typography sx={[title14, { textDecoration: "none" }]}>
                  {month} :
                </Typography>
                <Typography sx={text14wLeft}>{temperature}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box style={{ marginBottom: "24px" }}>
          <Box>
            <Typography sx={[title20, { width: "200px" }]}>
              Donma Derinliği
            </Typography>
            <Box sx={[dvdr50, { marginBottom: "12px" }]} />

            <Box>
              <List
                sx={{
                  bgcolor: "background.paper",
                  height: "200px",
                  borderRadius: "12px",
                }}
              >
                {Object.entries(frostDepth).map(([depth, value]) => (
                  <ListItem key={depth}>
                    <Typography sx={[title14, { textDecoration: "none" }]}>
                      {depth} :{" "}
                    </Typography>{" "}
                    <Typography sx={text14wLeft}> {value}</Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DetailStaticsCity;
