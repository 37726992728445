import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { modalStyle, subTitle, titleStyle } from "src/theme/customStyles";

import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
function DonanimDetailModal(props) {
  const [detail, setDetail] = React.useState([]);
  const [hasWorkers, setHasWorkers] = useState(false);

  React.useEffect(() => {
    getWorkerGroupDetail();
  }, [props.detailModal]);

  const getWorkerGroupDetail = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`https://dev.plancim.com/api/item/${props.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDetail(result);

        const hasItemGroups =
          result.itemGroups &&
          result.itemGroups.length > 0 &&
          result.itemGroups[0].id !== null;

        setHasWorkers(hasItemGroups);
      })
      .catch((error) => console.log("error", error));
  };

  const ItemGroupComponent = ({ group }) => {
    return (
      <div>
        <p>
          <strong>Group ID:</strong> {group.id}
        </p>
        <p>
          <strong>Group Name:</strong> {group.name}
        </p>
      </div>
    );
  };
  return (
    <>
      {detail != [] && (
        <Modal
          open={props.detailModal}
          onClose={() => props.setDetailModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={[
              modalStyle,
              {
                width: "90%",
                maxWidth: "960px", // Maksimum genişlik
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#F0F3FF",
                top: "5%",
              },
            ]}
          >
            <Box
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={titleStyle}>DETAY</Typography>
              <IconButton
                sx={{ alignSelf: "flex-end" }}
                color="black"
                onClick={() => props.setDetailModal(false)}
              >
                <CloseIcon sx={{ fontSize: "32px" }} />
              </IconButton>
            </Box>

            <Box>
              <div>
                <p>
                  <strong>İsim:</strong> {detail?.name}
                </p>
                <p>
                  <strong>Poz:</strong> {detail?.poz}
                </p>
                <p>
                  <strong>Sabit Veri:</strong>{" "}
                  {detail?.isMain ? "Evet" : "Hayır"}
                </p>
                <h3>Donanım Grupları</h3>
                {hasWorkers ? (
                  detail?.itemGroups.map((group) =>
                    group.id ? (
                      <div key={group.id}>
                        <ItemGroupComponent group={group} />
                      </div>
                    ) : null
                  )
                ) : (
                  <p>Donanım grubu yok.</p>
                )}
              </div>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default DonanimDetailModal;
