import React, { useState } from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { modalStyleNewest } from "src/utils/cssStyles";
import {
  subTitle,
  text14wLeft,
  title14,
  titleStyle,
} from "src/theme/customStyles";

const CompanyDetailModal = (props) => {
  const [company, setCompany] = useState([]);
  React.useEffect(() => {
    getCompany();
  }, [props.detailModal]);

  const getCompany = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`http://85.104.112.4:400/api/partner/${props.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCompany(result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {company && (
        <Modal
          open={props.detailModal}
          onClose={() => props.setDetailModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyleNewest}>
            <Box
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={titleStyle}>Şirket Detayları</Typography>

              <IconButton
                sx={{ alignSelf: "flex-end" }}
                color="black"
                onClick={() => props.setDetailModal(false)}
              >
                <CloseIcon sx={{ fontSize: "32px" }} />
              </IconButton>
            </Box>
            <Typography style={subTitle}>{company?.name} Detayları</Typography>
            <Box
              style={{
                gap: "12px",
                display: "flex",
                flexDirection: "column",
                marginTop: "24px",
              }}
            >
              <Box style={{ display: "flex" }}>
                <Typography style={title14}>Açıklama:</Typography>
                <Typography style={text14wLeft}>
                  {company?.description}
                </Typography>
              </Box>

              <Box style={{ display: "flex" }}>
                <Typography style={title14}>Adres:</Typography>
                <Typography style={text14wLeft}>
                  {company?.address?.addressLine}, {company?.address?.district},{" "}
                  {company?.address?.city}
                </Typography>
              </Box>
              <Box style={{ display: "flex" }}>
                <Typography style={title14} variant="h3">
                  Malzemeler
                </Typography>

                {company?.material && company.material.length > 0 ? (
                  company.material.map((material) => (
                    <div key={material.id}>
                      <Typography style={title14}>
                        <strong>Ad:</strong>
                      </Typography>
                      <Typography style={text14wLeft}>
                        {material.name}
                      </Typography>

                      <Typography style={title14}>
                        <strong>Türler:</strong>
                      </Typography>
                      <Typography style={text14wLeft}>
                        {material.types.join(", ")}
                      </Typography>
                    </div>
                  ))
                ) : (
                  <Typography style={text14wLeft}>Malzeme yok.</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default CompanyDetailModal;
