import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  modalStyle,
  subTitle,
  text14wLeft,
  title14,
  titleStyle,
} from "src/theme/customStyles";

import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import { modalStyleNewest } from "src/utils/cssStyles";
function WorkerGroupModal(props) {
  const [detail, setDetail] = React.useState([]);
  const [hasWorkers, setHasWorkers] = useState(false);

  React.useEffect(() => {
    getWorkerGroupDetail();
  }, [props.detailModal]);

  const getWorkerGroupDetail = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `https://dev.plancim.com/api/worker/group/${props.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setDetail(result);

        const hasWorkers =
          result.workers.length > 0 && result.workers[0].id !== null;

        setHasWorkers(hasWorkers);
      })
      .catch((error) => console.log("error", error));
  };

  const WorkerProfileComponent = ({ profile }) => {
    return (
      <div>
        <p>
          <strong>İsim:</strong> {profile?.name} {profile?.surname}
        </p>
        {profile?.pic && (
          <img
            src={profile?.pic}
            alt={`${profile?.name} ${profile?.surname}`}
            width={50}
            height={50}
          />
        )}
        <p>
          <strong>Cinsiyet:</strong>{" "}
          {profile?.gender == "m"
            ? "Erkek"
            : profile?.gender == "w"
            ? "Kadın"
            : "Diğer"}
        </p>
      </div>
    );
  };

  return (
    <>
      {detail != [] && (
        <Modal
          open={props.detailModal}
          onClose={() => props.setDetailModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={[modalStyleNewest]}>
            <Box
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={titleStyle}>DETAY</Typography>
              <IconButton
                sx={{ alignSelf: "flex-end" }}
                color="black"
                onClick={() => props.setDetailModal(false)}
              >
                <CloseIcon sx={{ fontSize: "32px" }} />
              </IconButton>
            </Box>
            <Typography style={subTitle}>{detail?.name} Detayları</Typography>

            <Box style={{ marginTop: "24px" }}>
              <div>
                <Box sx={{ display: "flex" }}>
                  <Typography style={title14}>Açıklama:</Typography>
                  <Typography style={text14wLeft}>
                    {detail?.description}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "12px",
                  }}
                >
                  <Typography style={title14} variant="h3">
                    Çalışanlar:
                  </Typography>
                  {hasWorkers ? (
                    detail.workers.map((worker) =>
                      worker.id && worker.profile ? (
                        <Box key={worker.id} sx={{ mb: "36px" }}>
                          <WorkerProfileComponent profile={worker.profile} />
                        </Box>
                      ) : null
                    )
                  ) : (
                    <Typography style={text14wLeft}>
                      Çalışan bulunmamaktadır.
                    </Typography>
                  )}
                </Box>
              </div>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default WorkerGroupModal;
