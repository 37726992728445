// @mui
import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

export function bgBlur(props) {
  const color = props?.color || "#000000";
  const blur = props?.blur || 6;
  const opacity = props?.opacity || 0.8;
  const imgUrl = props?.imgUrl;

  if (imgUrl) {
    return {
      position: "relative",
      backgroundImage: `url(${imgUrl})`,
      "&:before": {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 9,
        content: '""',
        width: "100%",
        height: "100%",
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity),
      },
    };
  }

  return {
    backdropFilter: `blur(${blur}px)`,
    WebkitBackdropFilter: `blur(${blur}px)`,
    backgroundColor: alpha(color, opacity),
  };
}

// ----------------------------------------------------------------------

export function bgGradient(props) {
  const direction = props?.direction || "to bottom";
  const startColor = props?.startColor;
  const endColor = props?.endColor;
  const imgUrl = props?.imgUrl;
  const color = props?.color;

  if (imgUrl) {
    return {
      background: `linear-gradient(${direction}, ${startColor || color}, ${
        endColor || color
      }), url(${imgUrl})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundColor: "red",
    };
  }

  return {
    background: `linear-gradient(${direction}, ${startColor}, ${endColor})`,
  };
}

// ----------------------------------------------------------------------

export function textGradient(value) {
  return {
    background: `-webkit-linear-gradient(${value})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };
}

// ----------------------------------------------------------------------

export function filterStyles(value) {
  return {
    filter: value,
    WebkitFilter: value,
    MozFilter: value,
  };
}

// ----------------------------------------------------------------------

export const hideScrollbarY = {
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

// ----------------------------------------------------------------------

export const hideScrollbarX = {
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  overflowX: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

// ----------------------------------------------------------------------

export const boxStyle = {
  border: "1px solid #D6E4FF",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#ffffff",
  padding: "16px",

  alignItems: "center",
  justifyContent: "center",
  borderRadius: "16px",
};

export const imgBoxStyle = {
  width: "70px",
  height: "70px",
  borderRadius: "999999999999px",
  backgroundColor: "#F9FAFB",
  alignItems: "center",
  border: "1px solid #D6E4FF",
  display: "flex",
  justifyContent: "center",
  marginBottom: "12px",
};

export const text183838bold = {
  fontSize: "18px",
  color: "#383838",
  fontWeight: "bold",
};

export const text14383838 = { fontSize: "14px", color: "#383838" };

export const hordvdr = {
  width: "100%",
  height: "1px",
  backgroundColor: "lightgray",
  marginTop: "12px",
  marginBottom: "12px",
};

export const actionsBox = {
  display: "flex",
  flexWrap: "nowrap",
  flexDirection: "row",
  gap: "1rem",
};

export const mainContainer = {
  marginLeft: "2.5%",
  marginTop: "0",
  marginLeft: "1%",
  paddingRight: "2.5%",
};

export const headerBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "2rem",
};

export const headerTitle = {
  fontSize: "24px",
  fontWeight: "bold",
  color: "#181e2e",
  letterSpacing: "0.01em",
  marginRight: "auto",
};

export const headerSubTitle = {
  fontSize: "16px",
  fontWeight: "bold",
  color: "primary.second",
  letterSpacing: "0.01em",
};

export const modalStyleNewest = {
  position: "absolute",
  width: { xs: "343px", md: "400px", lg: "600px" },
  height: "600px",
  bgcolor: "background.paper",
  border: "2px solid #181e2e",
  boxShadow: 24,
  p: 4,
  justifyContent: "flex-start",
  display: "flex",
  flexDirection: "column",
  borderRadius: 4,
  alignSelf: "center",
  justifySelf: "center",
  left: { xs: "20%", md: "40%", lg: "40%" },
  top: "20%",
  overflow: "auto",
};
