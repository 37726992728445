import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
  MRT_ToggleDensePaddingButton,
} from "material-react-table";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import { Helmet } from "react-helmet-async";
import { MRT_Localization_TR } from "material-react-table/locales/tr";
import Transactions from "src/components/Icons/IconJS/Transactions";
import UserIcon from "src/components/Icons/IconJS/User";
import Iconify from "src/components/iconify";
import uuid from "react-uuid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MuiAlert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UserDetailModal from "src/components/YobComponents/UserDetailModal/UserDetailModal";
import LeftIcon from "src/assetsIcon/leftIcon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { mainContainer } from "src/utils/cssStyles";
import {
  inPag,
  pageTitle,
  paginationCont,
  renderTopToolbarCustomActionsCont,
  subTitle,
  titleContainer,
  tlbarins,
  tolbr,
} from "src/theme/customStyles";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import FilterComponent from "src/components/FilterComponent/FilterComponent";

const UserPage = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [salary, setSalary] = React.useState(null);
  const [gender, setGender] = React.useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [imageBase64, setImageBase64] = useState("");
  const [mail, setMail] = React.useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();

  const [jobId, setJobId] = React.useState("");
  const [allJobIds, setAllJobIds] = React.useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [allInfoById, setAllInfoById] = useState([]);
  const [filterPopover, setFilterPopover] = useState(false);
  const [typeId, setTypeId] = useState(null);

  const [addUserIndicator, setAddUserIndicator] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdUpdate, setSelectedIdUpdate] = useState(null);
  const [getAllPage, setGetAllPage] = useState(1);
  const [sortingType, setSortingType] = useState("name");

  const [modalType, setModalType] = useState("");

  const fileInputRef = useRef();

  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const openSort = Boolean(anchorElSort);

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleCloseSort = (item) => {
    setAnchorElSort(null);
    setSortingType(item);
  };

  React.useEffect(() => {
    getUsers();

    if (open == false) {
      setName("");
      setSurname("");
      setMail("");
      setJobId("");
      setImageBase64("");
      setImagePreview("");
      setGender("");
      setPhone("");
      setSalary(null);
      setSearch("");
      setSelectedIdUpdate();
      setModalType("");
    }
  }, [addUserIndicator, open, deleteModal, getAllPage, sortingType, typeId]);

  React.useEffect(() => {
    getJobIds(page);
  }, [page]);

  React.useEffect(() => {
    if (deleteModal == false) {
      setSelectedId(null);
    }
  }, [deleteModal]);

  // ARAMA //
  const [search, setSearch] = useState("");

  const searchTimeout = useRef(null);

  const handleSearch = (value) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      getUsers();
    }, 1000);
  };
  // ARAMA //

  // Pagination Component //

  const [totalPage, setTotalPage] = useState(1);
  useEffect(() => {
    getUsers();
  }, [getAllPage, typeId]);

  const handleChange = (event) => {
    setGetAllPage(event.target.value);
  };
  //

  const handleOpen = () => {
    setName("");
    setSurname("");
    setMail("");
    setJobId("");
    setImageBase64("");
    setImagePreview("");
    setGender("");
    setPhone("");
    setSalary(null);
    setSearch("");
    setOpen(true);
    setSelectedIdUpdate();
    setModalType("");
  };
  const handleClose = () => setOpen(false);

  //// GET ALL ////
  const getUsers = async () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // URL'i oluştururken searchText parametresini koşullu olarak ekleyin
    let url = `https://dev.plancim.com/api/worker?per_page=10&page=${getAllPage}&sort_by=${sortingType}&sort_type=ASC`; // &Filter_TypeId=`${}`
    if (search && search.trim() !== "") {
      url += `&search_text=${search}`;
    }

    if (typeId != "" && typeId != null) {
      // url += &Filter_TypeId=`${typeId}`
    }

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode == 401) {
          navigate("/401");
        }
        var endpointsArr = [];
        setTotalPage(result.pagination.totalPage);

        for (let i = 0; i < result.items.length; i++) {
          const user = result.items[i];
          endpointsArr.push({
            Img: user.profile?.pic ? (
              <img
                alt="avatar"
                src={user.profile?.pic}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                }}
              />
            ) : (
              <img
                alt="avatar"
                src={
                  user.profile?.gender == "m"
                    ? "/assets/icons/PersonelAssets/employee.png"
                    : "/assets/icons/PersonelAssets/employeewoman.png"
                }
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                }}
              />
            ),
            ID: user.id,
            Name: user.profile.name,
            Surname: user.profile.surname,
            Job: user.job?.name,
          });
        }

        setData(endpointsArr);
        setLoading(false);
      })
      .catch((error) => console.log("from get all", error));
  };
  //// GET ALL ////

  //// EKLEME ////
  const addUsers = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      profile: {
        name: name,
        surname: surname,
        pic: imageBase64 == "" ? undefined : imageBase64,
        gender: gender,
      },
      communicationInfo: {
        email: mail == "" ? undefined : mail,
        phone: phone == "" ? undefined : { code: "+90", number: phone },
      },
      jobId: jobId == "" ? undefined : jobId,
      salary: salary == "" ? undefined : parseFloat(salary),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://dev.plancim.com/api/worker/${uuid()}`, requestOptions)
      .then((response) => {
        response.json();
        if (response.ok) {
          console.log(response);
          setAlertStatus(true);

          setAlertMessage("Personel başarıyla eklendi.");
          handleAlertClick();
        } else {
          setAlertMessage(response.message);
          setAlertStatus(false);
          handleAlertClick();
        }
      })
      .then((result) => {
        setAddUserIndicator(!addUserIndicator);
        setOpen(false);
        console.log(result);
      })
      .catch((error) => console.log("from add error", error));

    setLoading(false);
  };
  //// EKLEME ////

  //// SİLME ////

  const deleteUser = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`https://dev.plancim.com/api/worker/${selectedId}`, requestOptions)
      .then((response) => {
        response.json();
        if (response.ok) {
          console.log(response);
          setAlertStatus(true);
          setAlertMessage("Personel silindi");
          handleAlertClick();
        } else {
          console.log(response);
          setAlertMessage("İşlem başarısız.");
          setAlertStatus(false);
          handleAlertClick();
        }
      })
      .then((result) => {})
      .catch((error) => console.log("error", error));
    setDeleteModal(false);
    setSelectedId(null);
    setLoading(false);
  };
  //// SİLME ////

  // get job ID //
  const getJobIds = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `https://dev.plancim.com/api/job?per_page=70&page=${page}&sort_by=name&sort_type=ASC`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        console.log("giriok");
        setAllJobIds((prevJobIds) => [...prevJobIds, ...result.items]);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };
  // get job ID //

  // ID'YE GÖRE//
  const getUserById = async (id, state) => {
    console.log("???");
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`https://dev.plancim.com/api/worker/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setName(result.profile.name);
        setSurname(result.profile.surname);
        setGender(result.profile?.gender);
        setImagePreview(result.profile?.pic);

        let phone = result.communicationInfo.phone?.number;
        if (phone == undefined) {
          setPhone("");
        } else {
          setPhone(result.communicationInfo.phone.number);
        }

        setMail(result.communicationInfo.email);

        setJobId(result?.job?.id);
        setSalary(result?.salary?.net);

        console.log(result);

        if (state == "detay") {
          setAllInfoById(result);
        } else {
          setOpen(true);
        }
      })
      .catch((error) => console.log("error _____", error));

    setLoading(false);
  };
  // ID'YE GÖRE//

  const getUserByIdDetail = async (id, state) => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`https://dev.plancim.com/api/worker/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllInfoById(result);

        setDetailModal(true);
      })
      .catch((error) => console.log("error???", error));

    setLoading(false);
  };

  // UPDATE //
  const updateUserById = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      profile: {
        name: name,
        surname: surname,
        pic: imageBase64 == "" ? undefined : imageBase64,
        gender: gender,
      },
      communicationInfo: {
        email: mail == "" ? undefined : mail,
        phone: phone == "" ? undefined : { code: "+90", number: phone },
      },
      jobId: jobId == "" ? undefined : jobId,
      salary: salary == "" ? undefined : parseFloat(salary),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `https://dev.plancim.com/api/worker/${selectedIdUpdate}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          console.log(response);
        }
        return response.json();
      })
      .then((result) => {
        if (result.statusCode == 400) {
          setAlertMessage(result.message);
          setAlertStatus(false);
          handleAlertClick();
        } else {
          setAlertMessage("İşlem Başarılı");
          setAlertStatus(true);
          handleAlertClick();
        }

        setOpen(false);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };
  // UPDATE //

  const columns = useMemo(
    () => [
      {
        accessorKey: "Img",
        header: "Fotoğraf",
        size: 120,
      },
      {
        accessorKey: "Name",
        header: "İsim",
        size: 120,
      },
      {
        accessorKey: "Surname",
        header: "Soy İsim",
        size: 120,
      },
      {
        accessorKey: "Job",
        header: "Meslek",
        size: 120,
      },
    ],
    []
  );

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setImageBase64(base64String);
        setImagePreview(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop >
      event.target.clientHeight;

    console.log(bottom);

    if (bottom) {
      console.log("girdi mi");

      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleTelefonNumarasiChange = (event) => {
    const value = event.target.value;

    // Sadece rakam kabul et
    const sanitizedValue = value.replace(/\D/g, "");

    // 10 haneden fazla değilse ve 0 ile başlamıyorsa değeri güncelle
    if (sanitizedValue.length <= 10 && sanitizedValue[0] !== "0") {
      setPhone(sanitizedValue);
    }
  };

  // Telefon numarasını formatla (3, 3, 4)
  const formatTelefonNumarasi = (value) => {
    const formattedValue = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
    return formattedValue.trim();
  };

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(Boolean);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const style = {
    position: "absolute",
    top: "5%",
    left: { xs: "0%", md: "30%", lg: "40%" },
    width: "434px",
    minWidth: "343px",
    minHeight: "373px",
    bgcolor: "background.paper",
    border: "2px solid #181e2e",
    boxShadow: 24,
    p: 4,
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    borderRadius: 4,
    overflow: "auto", // İçerik kutunun dışına taştığında scroll bar görünür
  };

  return (
    <Box style={mainContainer}>
      <LoadingOverlay isOpen={loading} />
      <Helmet Helmet>
        <title> Personeller | Plancım </title>
      </Helmet>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: !imagePreview && "1rem",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              sx={{ color: "primary.main" }}
            >
              Yeni Personel Ekle
            </Typography>

            <Box
              style={{
                width: "50%",
                height: "0.01rem",
                backgroundColor: "#181e2e",
                opacity: 0.2,
                marginTop: "1%",
                borderRadius: 16,
              }}
            />
          </Box>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {imagePreview && (
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "999999999999px",
                  backgroundColor: "#DFE3E8",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{
                    borderRadius: "999999999999px",
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                  }}
                />
              </div>
            )}
            {!imagePreview && (
              <Button
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "999999999999px",
                  backgroundColor: "#F9FAFB",
                  alignItems: "center",
                  border: "1px dotted #383838",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CameraAltIcon style={{ fontSize: "48px" }} />
              </Button>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <button onClick={handleButtonClick}>Resim Seç</button>
          </div>

          <TextField
            name="İsim*"
            label="İsim*"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            style={{ marginBottom: "1rem", marginTop: !imagePreview && "1rem" }}
          />

          <TextField
            name="Soyisim*"
            label="Soyisim*"
            value={surname}
            onChange={(e) => {
              setSurname(e.target.value);
            }}
            style={{ marginBottom: "1rem" }}
          />

          <TextField
            name="Mail Adresi"
            label="Mail Adresi"
            value={mail}
            onChange={(e) => {
              setMail(e.target.value);
            }}
            style={{ marginBottom: "1rem" }}
          />

          <TextField
            name="telefon"
            label="Telefon Numarası"
            placeholder="Lütfen başına +90 yazmadan telefon numaranızı girin."
            value={formatTelefonNumarasi(phone)}
            onChange={handleTelefonNumarasiChange}
            inputProps={{ maxLength: 10 }}
            sx={{
              borderColor: "red",
              marginBottom: "1rem",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+90</InputAdornment>
              ),
            }}
          />

          <TextField
            name="Net Maaş"
            label="Net Maaş"
            value={salary}
            onChange={(e) => {
              setSalary(e.target.value);
            }}
            style={{ marginBottom: "1rem" }}
          />

          <TextField
            id="outlined-select-gender"
            select
            label="Cinsiyet Seçin*"
            value={gender}
            onChange={(event) => setGender(event.target.value)}
            style={{ marginBottom: "1rem" }}
          >
            <MenuItem value={"m"}>Erkek</MenuItem>
            <MenuItem value={"w"}>Kadın</MenuItem>
            <MenuItem value={"o"}>Diğer</MenuItem>
          </TextField>

          <TextField
            id="outlined-select-job"
            select
            label="Meslek Seçiniz*"
            value={jobId}
            onChange={(event) => setJobId(event.target.value)}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  onScroll: handleScroll,
                },
              },
            }}
          >
            {allJobIds.map((res) => (
              <MenuItem key={res.id} value={res.id}>
                {res.name}
              </MenuItem>
            ))}
          </TextField>

          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              if (modalType == "edit") {
                updateUserById();
              } else {
                if (
                  name == "" ||
                  surname == "" ||
                  gender == "" ||
                  jobId == ""
                ) {
                  setAlertMessage(
                    "İsim , soyisim ,cinsiyet ve meslek boş girilemez."
                  );
                  setAlertStatus(false);
                  handleAlertClick();
                } else {
                  addUsers();
                }
              }
            }}
            color="success"
            style={{ color: "white", marginTop: "1rem" }}
          >
            {modalType == "edit" ? (
              <span>Güncelle</span>
            ) : (
              <span>Yeni Personel Ekle</span>
            )}
          </Button>

          <Typography
            sx={{
              color: "grey[400]",
              fontSize: 14,
              alignSelf: "center",
              marginTop: "0.5rem",
            }}
          >
            İsim , soyisim ,cinsiyet ve meslek boş girilemez.
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={[
            style,
            {
              width: "%30",
              height: "30%",
              top: "25%",
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Box
            style={{
              marginBottom: "24px",
              width: "100px",
              height: "100px",
              backgroundColor: "#F4F6F8",
              borderRadius: 999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DeleteForeverIcon color="error" sx={{ fontSize: "64px" }} />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h3"
            sx={{
              color: "primary.main",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            Personeli silmek istediğinize emin misiniz ?
          </Typography>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="success"
              sx={{
                color: "white",
                marginRight: "1rem",
                fontSize: 16,
                borderRadius: 16,
                width: "120px",
              }}
              endIcon={<CheckIcon />}
              onClick={() => deleteUser()}
            >
              Evet
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                color: "error",
                marginRight: "1rem",
                fontSize: 16,
                borderRadius: 16,
                width: "120px",
              }}
              endIcon={<CloseIcon />}
              onClick={() => setDeleteModal(false)}
            >
              Vazgeç
            </Button>
          </Box>
        </Box>
      </Modal>
      {detailModal && loading == false && allInfoById != [] && (
        <UserDetailModal
          setDetailModal={setDetailModal}
          detailModal={detailModal}
          title={"Kullanıcı"}
          allInfoById={allInfoById}
        />
      )}
      <Box sx={titleContainer}>
        <Typography sx={pageTitle}>PERSONEL İŞLEMLERİ</Typography>
        <Typography sx={subTitle}>Personel {">"} Personel İşlemleri</Typography>
      </Box>
      <Box sx={{ position: "relative" }}>
        <MaterialReactTable
          localization={MRT_Localization_TR}
          columns={columns}
          data={data}
          enableRowSelection
          muiTableBodyCellProps={{
            sx: {
              color: "primary.main",
              fontWeight: "600",
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              color: "primary.main",
              letterSpacing: "1px",
              fontWeight: "600",
              opacity: "0.8",
            },
          }}
          enableColumnActions={false}
          action
          positionToolbarAlertBanner="top"
          positionActionsColumn="last"
          enableRowActions
          enablePagination={false}
          renderBottomToolbar={({ table, index }) => (
            <Box sx={tolbr}>
              <Box style={paginationCont}>
                <Box sx={inPag}>
                  <IconButton
                    onClick={() => {
                      if (getAllPage > 1) {
                        setGetAllPage(getAllPage - 1);
                      }
                    }}
                  >
                    <LeftIcon />
                  </IconButton>
                  <IconButton
                    sx={{ transform: "scaleX(-1) " }}
                    onClick={() => setGetAllPage(getAllPage + 1)}
                  >
                    <LeftIcon />
                  </IconButton>
                  <FormControl
                    size="small"
                    sx={{ width: "75px", minWidth: "50px" }}
                  >
                    <InputLabel id="demo-simple-select-label">Sayfa</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={getAllPage}
                      label="Sayfa"
                      onChange={handleChange}
                    >
                      {Array.from({ length: totalPage }, (_, index) => (
                        <MenuItem key={index} value={index + 1}>
                          {index + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          )}
          renderRowActions={({ row, table }) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                flexDirection: "row",
                gap: "1rem",

                width: "100px",
              }}
            >
              <Tooltip title="Detay">
                <IconButton
                  onClick={() => {
                    getUserByIdDetail(row.original.ID);
                  }}
                >
                  <VisibilityIcon
                    color={"warning.secondary"}
                    style={{ fontSize: "24px" }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title="Düzenle">
                <IconButton
                  onClick={() => {
                    console.log(row.original.ID);
                    getUserById(row.original.ID, "");
                    setModalType("edit");
                    setSelectedIdUpdate(row.original.ID);
                  }}
                >
                  <EditIcon color={"success"} style={{ fontSize: "24px" }} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Sil">
                <IconButton
                  onClick={() => {
                    setDeleteModal(true);
                    setSelectedId(row.original.ID);
                  }}
                >
                  <DeleteForeverIcon
                    color={"error"}
                    style={{ fontSize: "24px" }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => (
            <Box sx={[renderTopToolbarCustomActionsCont, { display: "flex" }]}>
              <TextField
                name="Arama"
                label="Arama.."
                value={search}
                onKeyDown={handleSearch}
                onChange={(e) => {
                  const { value } = e.target;
                  setSearch(value);
                  handleSearch(value);
                }}
                sx={{
                  marginRight: "12px",
                }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                id="basic-button"
                aria-controls={openSort ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openSort ? "true" : undefined}
                onClick={handleClickSort}
                sx={{
                  color: "primary.main",
                  marginRight: "1rem",
                  textTransform: "none",
                }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Sırala
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElSort}
                open={openSort}
                onClose={() => handleCloseSort(sortingType)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleCloseSort("name")}>
                  İsime Göre
                </MenuItem>
                <MenuItem onClick={() => handleCloseSort("surname")}>
                  Soyisime Göre
                </MenuItem>
                <MenuItem onClick={() => handleCloseSort("job")}>
                  Mesleğe Göre
                </MenuItem>
              </Menu>
            </Box>
          )}
          //customize built-in buttons in the top-right of top toolbar
          renderToolbarInternalActions={({ table }) => (
            <Box style={paginationCont}>
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={handleOpen}
                sx={{
                  backgroundColor: "success.main",
                  fontSize: { xs: "10px", md: "12px", lg: "14px" },
                }}
              >
                Yeni Personel
              </Button>
            </Box>
          )}
        />
        <FilterComponent
          typeId={typeId}
          setTypeId={setTypeId}
          type={"Meslek"}
        />
      </Box>

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserPage;
