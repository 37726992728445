// component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SvgColor from "../../../components/svg-color";
import {
  faBuilding,
  faCartFlatbed,
  faDatabase,
  faFileInvoiceDollar,
  faFolderOpen,
  faGear,
  faHelmetSafety,
  faHouse,
  faIndustry,
  faMoneyBill,
  faSheetPlastic,
  faUserTie,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "Anasayfa",
    path: "/dashboard/Anasayfa",
    icon: <FontAwesomeIcon icon={faHouse} size="lg" />,
  },
  {
    title: "Proje",
    path: "/dashboard/proje",
    icon: <FontAwesomeIcon icon={faFolderOpen} size="lg" />,
    subMenu: [
      {
        title: "Proje Oluştur",
        path: "/dashboard",
      },
      {
        title: "Proje İşlemleri",
        path: "/dashboard",
      },
    ],
  },
  {
    title: "Kadro / Personel",
    path: "/dashboard/personel",
    icon: <FontAwesomeIcon icon={faUserTie} size="lg" />,
    subMenu: [
      {
        title: "Personel İşlemleri",
        path: "/dashboard/Personel",
      },
      {
        title: "Personel Grup İşlemleri",
        path: "/dashboard/PersonelGrubuIslemleri",
      },
      {
        title: "Meslek İşlemleri",
        path: "/dashboard/MeslekIslemleri",
      },
      {
        title: "Meslek Türü İşlemleri",
        path: "/dashboard/MeslekTuruIslemleri",
      },
      {
        title: "Ünvan İşlemleri",
        path: "/dashboard/Unvanlar",
      },
    ],
  },
  // {
  //   title: "Meslekler",
  //   path: "/dashboard/Meslekler",
  //   icon: icon("ic_blog"),
  // },
  {
    title: "Firma",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faBuilding} size="lg" />,
    subMenu: [
      {
        title: "Firma İşlemleri",
        path: "/dashboard/Firmalar",
      },
      {
        title: "Firma Bul",
        path: "/dashboard",
      },
    ],
  },
  {
    title: "Servisler",
    path: "/dashboard/",
    icon: <FontAwesomeIcon icon={faHelmetSafety} size="lg" />,
    subMenu: [
      {
        title: "Malzeme",
        path: "/dashboard/Personel",
        subMenu: [
          {
            title: "Malzeme Ana Başlık İşlemleri",
            path: "/dashboard/MalzemeAnaBaslik",
          },

          {
            title: "Malzeme Alt Başlık İşlemleri",
            path: "/dashboard/MalzemeAltBaslik",
          },
          {
            title: "Malzeme Tipi İşlemleri",
            path: "/dashboard/MalzemeBaslik",
          },
        ],
      },
      {
        title: "Taşeron",
        path: "/dashboard",
        subMenu: [
          {
            title: "Taşeron İşlemleri",
            path: "/dashboard/TaseronIslemleri",
          },
        ],
      },
      {
        title: "Hizmet",
        path: "/dashboard",
        subMenu: [
          {
            title: "Hizmet İşlemleri",
            path: "/dashboard/HizmetIslemleri",
          },
        ],
      },
    ],
  },
  {
    title: "Abonelikler",
    path: "/dashboard/Abonelikler",
    icon: <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" />,
  },
  {
    title: "Tesisler",
    path: "/dashboard/Tesisler",
    icon: <FontAwesomeIcon icon={faIndustry} size="lg" />,
  },
  {
    title: "Teklifler",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faSheetPlastic} size="lg" />,
    subMenu: [
      {
        title: "Verilen Teklif İşlemleri",
        path: "/dashboard",
      },
      {
        title: "Alınan Teklif İşlemleri",
        path: "/dashboard",
      },
    ],
  },
  {
    title: "Donanım",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faCartFlatbed} size="lg" />,
    subMenu: [
      {
        title: "Donanım İşlemleri",
        path: "/dashboard/DonanimIslemleri",
      },
      {
        title: "Donanım Grubu İşlemleri",
        path: "/dashboard/DonanimGrupIslemleri",
      },
    ],
  },

  {
    title: "Ekap (Çok Yakında)",
    path: "/dashboard",
    icon: icon("ic_lock"),
  },
  {
    title: "Veriler",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faDatabase} size="lg" />,
    subMenu: [
      {
        title: "Harita Görünümü",
        path: "/dashboard/Harita",
      },
      {
        title: "il Bazlı Veriler",
        path: "/dashboard/IlBazliVeriler",
      },
      {
        title: "ilçe Bazlı Veriler",
        path: "/dashboard/IlceBazliVeriler",
      },
      {
        title: "Havalimanı Verileri",
        path: "/dashboard/HavalimaniVerileri",
      },
      {
        title: "Liman Verileri",
        path: "/dashboard/LimanVerileri",
      },
      {
        title: "Demiryolu Verileri",
        path: "/dashboard/DemiryollariVerileri",
      },
      {
        title: "Gümrük Verileri",
        path: "/dashboard/GumrukVerileri",
      },
      {
        title: "Birim Fiyat (Çok Yakında)",
        path: "/Personel/PersonelGrupIslemler",
      },
    ],
  },
  {
    title: "Finansal İşlemler",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faMoneyBill} size="lg" />,
    subMenu: [
      {
        title: "Faturalar",
        path: "/dashboard",
      },
      {
        title: "Ödeme işlemleri",
        path: "/dashboard/Paket",
      },
      {
        title: "Paket değiştirme",
        path: "/dashboard/",
      },
      {
        title: "Kayıtlı Kart İşlemleri",
        path: "/dashboard/",
      },
      {
        title: "Fatura Bilgileri",
        path: "/dashboard/",
      },
    ],
  },

  {
    title: "Kullanıcı",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
    subMenu: [
      {
        title: "Kullanıcı İşlemleri",
        path: "/dashboard/KullaniciIslemleri",
      },
      {
        title: "Yetkiler ",
        path: "/dashboard/Yetkiler",
      },
      {
        title: "Yetki Gruplari ",
        path: "/dashboard",
      },
    ],
  },
  {
    title: "Ayarlar",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faGear} size="lg" />,
  },
];

export default navConfig;
