import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { modalStyle, subTitle, titleStyle } from "src/theme/customStyles";

import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import { modalStyleNewest } from "src/utils/cssStyles";
function JobDetailModal(props) {
  const [detail, setDetail] = React.useState();

  React.useEffect(() => {
    getJobByIdDetail();
  }, [props.detailModal]);

  const getJobByIdDetail = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`https://dev.plancim.com/api/job/${props.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDetail(result);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <>
      <Modal
        open={props.detailModal}
        onClose={() => props.setDetailModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyleNewest}>
          <Box
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography style={titleStyle}>DETAY</Typography>
            <IconButton
              sx={{ alignSelf: "flex-end" }}
              color="black"
              onClick={() => props.setDetailModal(false)}
            >
              <CloseIcon sx={{ fontSize: "32px" }} />
            </IconButton>
          </Box>

          <Box>
            <div>
              <p>
                <strong>İsim:</strong> {detail?.name}
              </p>
              <p>
                <strong>Ana İş:</strong> {detail?.isMain ? "Yes" : "No"}
              </p>
              <p>
                <strong>İş Türü:</strong> {detail?.jobType.name}
              </p>
              <p>
                <strong>Çalışanlar:</strong>{" "}
                {detail?.workers.length > 0
                  ? detail?.workers.join(", ")
                  : "Çalışan yok."}
              </p>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default JobDetailModal;
