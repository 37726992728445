import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialReactTable from "material-react-table";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import { Helmet } from "react-helmet-async";
import { MRT_Localization_TR } from "material-react-table/locales/tr";

import Iconify from "src/components/iconify";
import uuid from "react-uuid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import MuiAlert from "@mui/material/Alert";

import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LeftIcon from "src/assetsIcon/leftIcon";
import {
  filtreTemize,
  pagIns,
  pageTitle,
  paginationCont,
  renderTopToolbarCustomActionsCont,
  rowins,
  subTitle,
  titleContainer,
  tlbarins,
  tolbr,
} from "src/theme/customStyles";
import { mainContainer } from "src/utils/cssStyles";
import AirPortDetail from "src/components/YobComponents/StaticDetail/AirportDetail";
import PageHeader from "src/components/Pageheader/PageHeader";

const HavalimaniVerileri = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");

  const [idToModal, setIdToModal] = React.useState("");
  const [nameToModal, setNameToModal] = React.useState("");

  const [detailModal, setDetailModal] = useState(false);

  const [addUserIndicator, setAddUserIndicator] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [sortingType, setSortingType] = useState("city_name");
  const [sortType, setSortType] = useState("ASC");

  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = React.useState([]);

  React.useEffect(() => {
    getFacilities();
    getCities();
    if (open == false) {
      setName("");
    }
  }, [addUserIndicator, open, detailModal, selectedCity, sortType]);

  //********************* API *********************//
  const getFacilities = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    // URL'i oluştururken searchText parametresini koşullu olarak ekleyin
    let url = `https://dev.plancim.com/api/static/airport?per_page=10&page=${getAllPage}&sort_by=${sortingType}&sort_type=${sortType}`;
    if (search && search.trim() !== "") {
      url += `&search_text=${search}`;
    }
    if (selectedCity !== "") {
      url += `&city_id=${selectedCity}`;
    }

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        var endpointsArr = [];
        console.log(result);

        setTotalPage(result.pagination.totalPage);

        for (let i = 0; i < result.items.length; i++) {
          const user = result.items[i];
          endpointsArr.push({
            ID: user.id,
            Name: user.name,
            CityName: user.cityName,
            status: user.status,
            use: user.use,
          });
        }

        setData(endpointsArr);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };

  const getCities = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    // URL'i oluştururken searchText parametresini koşullu olarak ekleyin
    let url = `https://dev.plancim.com/api/static/city?per_page=81&page=1&sort_by=name&sort_type=ASC`;

    // selectedCity null değilse, URL'e ekliyoruz

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCities(result.items);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  //********************* API *********************//

  const columns = useMemo(
    () => [
      {
        accessorKey: "CityName",
        header: "İl",
        size: 120,
      },
      {
        accessorKey: "Name",
        header: "Havalimanı Adı",
        size: 120,
      },

      {
        accessorKey: "status",
        header: "Durum",
        size: 120,
      },
      {
        accessorKey: "use",
        header: "Kullanım Alanı",
        size: 120,
      },
    ],
    []
  );

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
    filename: "User_Transactions",
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(Boolean);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  // ARAMA //
  const [search, setSearch] = useState("");

  const searchTimeout = useRef(null);

  const handleSearch = (value) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      getFacilities();
    }, 1000);
  };
  // ARAMA //

  // Sıralama //
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const openSort = Boolean(anchorElSort);

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleCloseSort = (item, item2) => {
    setAnchorElSort(null);
    setSortingType(item);
    setSortType(item2);
  };
  // Sıralama //

  // Pagination Component //

  const [totalPage, setTotalPage] = useState(1);
  const [getAllPage, setGetAllPage] = useState(1);
  useEffect(() => {
    getFacilities();
  }, [getAllPage]);

  const handleChange = (event) => {
    setGetAllPage(event.target.value);
  };
  //
  function CityDropdown() {
    const handleChange = (event) => {
      setSelectedCity(event.target.value);
    };

    return (
      <FormControl
        size="small"
        sx={{ width: "140px", minWidth: "50px", marginRight: "1rem" }}
      >
        <InputLabel id="city-dropdown-label">Şehir</InputLabel>
        <Select
          labelId="city-dropdown-label"
          id="city-dropdown"
          value={selectedCity}
          label="Şehir"
          onChange={handleChange}
        >
          {cities.map((city) => (
            <MenuItem key={city.id} value={city.id}>
              {city.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <Box style={mainContainer}>
      <LoadingOverlay isOpen={loading} />

      <Helmet Helmet>
        <title> Havalimanı Verileri | Plancım </title>
      </Helmet>

      <PageHeader
        title={"HAVALİMANI VERİLERİ"}
        subtitle={"Veriler > Havalimanı Verileri"}
      />

      <MaterialReactTable
        localization={MRT_Localization_TR}
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableRowSelection
        muiTableBodyCellProps={{
          sx: {
            color: "primary.main",
            fontWeight: "600",
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            color: "primary.main",
            letterSpacing: "1px",
            fontWeight: "600",
            opacity: "0.8",
          },
        }}
        positionToolbarAlertBanner="top"
        positionActionsColumn="last"
        enableRowActions
        enablePagination={false}
        renderBottomToolbar={({ table, index }) => (
          <Box sx={tolbr}>
            <Box sx={paginationCont}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "24px",
                  alignItems: "center",
                  marginRight: "1rem",
                }}
              >
                <IconButton
                  onClick={() => {
                    if (getAllPage > 1) {
                      setGetAllPage(getAllPage - 1);
                    }
                  }}
                >
                  <LeftIcon />
                </IconButton>
                <IconButton
                  sx={{ transform: "scaleX(-1) " }}
                  onClick={() => {
                    setGetAllPage(getAllPage + 1);
                  }}
                >
                  <LeftIcon />
                </IconButton>
                <FormControl
                  size="small"
                  sx={{ width: "75px", minWidth: "50px" }}
                >
                  <InputLabel id="demo-simple-select-label">Sayfa</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={getAllPage}
                    label="Sayfa"
                    onChange={handleChange}
                  >
                    {Array.from({ length: totalPage }, (_, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        )}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            setIdToModal(row.original.ID);
            setNameToModal(row.original.Name);
            setDetailModal(true);
          },
          sx: {
            cursor: "pointer", // Optional: Add a pointer cursor to indicate clickability
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)", // Optional: Add a hover effect
            },
          },
        })}
        renderRowActions={({ row, table }) => (
          <Box sx={rowins}>
            <Tooltip title="Detay">
              <IconButton
                onClick={() => {
                  setIdToModal(row.original.ID);
                  setNameToModal(row.original.Name);
                  setDetailModal(true);
                }}
              >
                <VisibilityIcon
                  color={"warning.secondary"}
                  style={{ fontSize: "24px" }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Düzenle">
              <IconButton disabled={true}>
                <EditIcon color={"#C4CDD5"} style={{ fontSize: "24px" }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Sil">
              <IconButton disabled={true}>
                <DeleteForeverIcon
                  color={"#C4CDD5"}
                  style={{ fontSize: "24px" }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={({ table }) => (
          <Box>
            <Box style={renderTopToolbarCustomActionsCont}>
              {CityDropdown()}

              <Button
                id="basic-button"
                aria-controls={openSort ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openSort ? "true" : undefined}
                onClick={handleClickSort}
                sx={{ color: "primary.main", marginRight: "1rem" }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Sırala
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElSort}
                open={openSort}
                onClose={() => handleCloseSort(sortingType)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleCloseSort("city_name", "ASC")}>
                  Şehir İsmine Göre A-Z
                </MenuItem>
                <MenuItem onClick={() => handleCloseSort("city_name", "DESC")}>
                  Şehir İsmine Z-A
                </MenuItem>
                <MenuItem onClick={() => handleCloseSort("airpot_name", "ASC")}>
                  Havalimanı İsmine Göre A-Z
                </MenuItem>
                <MenuItem
                  onClick={() => handleCloseSort("airpot_name", "DESC")}
                >
                  Havalimanı İsmine Göre Z-A
                </MenuItem>
                <MenuItem
                  onClick={() => handleCloseSort("airport_status", "ASC")}
                >
                  Havalimanı Durum İsmine Göre A-Z
                </MenuItem>
                <MenuItem
                  onClick={() => handleCloseSort("airport_status", "DESC")}
                >
                  Havalimanı Durum İsmine Göre Z-A
                </MenuItem>
              </Menu>
              <Button
                sx={{ color: "primary.main", marginRight: "1rem" }}
                id="basic-button"
                endIcon={<FilterAltIcon />}
              >
                Filtrele
              </Button>
            </Box>
            {selectedCity != "" && (
              <Button
                variant="outlined"
                sx={filtreTemize}
                onClick={() => setSelectedCity("")}
              >
                Filtreyi Temizle
              </Button>
            )}
          </Box>
        )}
        renderToolbarInternalActions={({ table }) => (
          <Box sx={paginationCont}>
            {/* <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              sx={{ backgroundColor: "success.main", fontSize: "14px" }}
              disabled={true}
            >
              Yeni Liman
            </Button> */}
          </Box>
        )}
      />

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>

      {detailModal && (
        <AirPortDetail
          id={idToModal}
          modalVisible={detailModal}
          setModalVisible={setDetailModal}
          type={"airport"}
        />
      )}
    </Box>
  );
};

export default HavalimaniVerileri;
