import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
  MRT_ToggleDensePaddingButton,
} from "material-react-table";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
  Chip,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import { Helmet } from "react-helmet-async";
import { MRT_Localization_TR } from "material-react-table/locales/tr";

import Iconify from "src/components/iconify";
import uuid from "react-uuid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MuiAlert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";

import LeftIcon from "src/assetsIcon/leftIcon";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { mainContainer } from "src/utils/cssStyles";
import {
  columncntr,
  dvdr50,
  inPag,
  miniBoxes,
  pageTitle,
  paginationCont,
  renderTopToolbarCustomActionsCont,
  rlminibox,
  subTitle,
  titleContainer,
  tlbarins,
  tolbr,
} from "src/theme/customStyles";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PageHeader from "src/components/Pageheader/PageHeader";
import WorkerGroupModal from "src/components/YobComponents/WorkerGroupModal/WorkerGroupModal";
import { useNavigate } from "react-router-dom";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";

const WorkerGroup = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [detailId, setDetailId] = React.useState(null);
  const navigate = useNavigate();
  const [workerIds, setWorkerIds] = React.useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);

  const [addUserIndicator, setAddUserIndicator] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdUpdate, setSelectedIdUpdate] = useState(null);
  const [getAllPage, setGetAllPage] = useState(1);
  const [sortingType, setSortingType] = useState("name");

  const [modalType, setModalType] = useState("");

  const fileInputRef = useRef();

  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const openSort = Boolean(anchorElSort);

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleCloseSort = (item) => {
    setAnchorElSort(null);
    setSortingType(item);
  };

  React.useEffect(() => {
    getUsers();

    if (open == false) {
      setName("");
      setDescription("");
      setWorkerIds([]);
      setSelectedIdUpdate();
      setModalType("");
      setSearch("");
    }
  }, [
    addUserIndicator,
    open,
    detailModal,
    deleteModal,
    getAllPage,
    sortingType,
  ]);

  React.useEffect(() => {
    if (deleteModal == false) {
      setSelectedId(null);
    }
  }, [deleteModal]);

  // ARAMA //
  const [search, setSearch] = useState("");

  const searchTimeout = useRef(null);

  const handleSearch = (value) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      getUsers();
    }, 1000);
  };
  // ARAMA //

  // Pagination Component //

  const [totalPage, setTotalPage] = useState(1);
  useEffect(() => {
    getUsers();
  }, [getAllPage]);

  const handleChange = (event) => {
    setGetAllPage(event.target.value);
  };
  //

  const handleOpen = () => {
    setName("");
    setDescription("");
    setSearch("");
    setWorkerIds([]);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  //// GET ALL ////
  const getUsers = async () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // URL'i oluştururken searchText parametresini koşullu olarak ekleyin
    let url = `https://dev.plancim.com/api/worker/group?per_page=10&page=${getAllPage}&sort_by=${sortingType}&sort_type=ASC`;
    console.log(url);
    if (search && search.trim() !== "") {
      url += `&search_text=${search}`;
    }

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        var endpointsArr = [];
        setTotalPage(result.pagination.totalPage);

        for (let i = 0; i < result.items.length; i++) {
          endpointsArr.push({
            Img: (
              <img
                alt="avatar"
                src={`/assets/icons/titles.png`}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                }}
              />
            ),
            ID: result.items[i].id,
            Name: result.items[i].name,
            workerNumber: result.items[i].workerNumber,
          });
        }
        if (result.statusCode == 401) {
          navigate("/401");
        }
        setData(endpointsArr);
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };
  //// GET ALL ////

  //// EKLEME ////
  const addWorkerGroup = async () => {
    console.log(workerIds);
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
      description: description,
      workerIds: workerIds,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://dev.plancim.com/api/worker/group/${uuid()}`, requestOptions)
      .then((response) => {
        response.json();
        if (response.ok) {
          console.log(response);
          setAlertStatus(true);

          setAlertMessage("Personel grubu başarıyla eklendi.");
          handleAlertClick();
        } else {
          setAlertMessage(response.message);
          setAlertStatus(false);
          handleAlertClick();
        }
      })
      .then((result) => {
        setAddUserIndicator(!addUserIndicator);
        setOpen(false);
        console.log(result);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };
  //// EKLEME ////

  //// SİLME ////

  const deleteUser = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `https://dev.plancim.com/api/worker/group/${selectedId}`,
      requestOptions
    )
      .then((response) => {
        response.json();
        if (response.ok) {
          setAlertStatus(true);
          setAlertMessage("Personel grubu silindi");
          handleAlertClick();
        } else {
          console.log(response);
          setAlertMessage("İşlem başarısız.");
          setAlertStatus(false);
          handleAlertClick();
        }
      })
      .then((result) => {})
      .catch((error) => console.log("error", error));
    setDeleteModal(false);
    setSelectedId(null);
    setLoading(false);
  };
  //// SİLME ////

  // ID'YE GÖRE//
  const getUserById = async (id) => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`https://dev.plancim.com/api/worker/group/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setName(result.name);
        setDescription(result.description);

        setOpen(true);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };
  // ID'YE GÖRE//

  // UPDATE //
  const updateUserById = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
      description: description,
      workerIds: workerIds,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `https://dev.plancim.com/api/worker/group/${selectedIdUpdate}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          console.log(response);
        }
        return response.json();
      })
      .then((result) => {
        if (result.statusCode == 400) {
          setAlertMessage(result.message);
          setAlertStatus(false);
          handleAlertClick();
        } else {
          setAlertMessage("İşlem Başarılı");
          setAlertStatus(true);
          handleAlertClick();
        }

        setOpen(false);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };
  // UPDATE //

  const [allWorkers, setAllWorkers] = useState([]);

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setWorkerIds(typeof value === "string" ? value.split(",") : value);
  };

  const handleDelete = (workerIdToDelete) => () => {
    setWorkerIds((prevWorkerIds) =>
      prevWorkerIds.filter((workerId) => workerId !== workerIdToDelete)
    );
  };

  // GET ALL USER //
  const getAllWorkers = async () => {
    console.log("atıyoz mu?");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // URL'i oluştururken searfhText parametresini koşullu olarak ekleyin
    let url = `https://dev.plancim.com/api/worker?per_page=10&page=${page}&sort_by=${sortingType}&sort_type=ASC`;

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        var endpointsArr = [];

        setAllWorkers((prevWorkers) => {
          const updatedWorkers = [...prevWorkers];
          result.items.forEach((item) => {
            if (!updatedWorkers.some((worker) => worker.id === item.id)) {
              updatedWorkers.push(item);
            }
          });
          return updatedWorkers;
        });
      })
      .catch((error) => console.log("error", error));
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "Name",
        header: "İsim",
        size: 120,
      },

      {
        accessorKey: "workerNumber",
        header: "Çalışan Sayısı",
        size: 333,
      },
    ],
    []
  );

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
    filename: "Personel Grupları",
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let url = `https://dev.plancim.com/api/worker/group?per_page=999&page=1&sort_by=${sortingType}&sort_type=ASC`;
    if (search && search.trim() !== "") {
      url += `&search_text=${search}`;
    }

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        csvExporter.generateCsv(result.items);
      })
      .catch((error) => console.log("error", error));

    setLoading(false);
  };

  useEffect(() => {
    getAllWorkers();
  }, [page]);

  useEffect(() => {
    // allWorkers dizisinin uzunluğu 9'un katları olduğunda ve boş olmadığında çalışacak
    if (allWorkers.length >= 9 * page) {
      setPage(page + 1); // Bir sonraki sayfaya geç
    }
  }, [allWorkers.length, page]);

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop >=
      event.target.clientHeight;
    if (bottom) {
      console.log(bottom);
      console.log("bottom?");
      setPage((prevPage) => prevPage + 1); // Sayfayı arttırarak yeni veri yüklemeyi tetikle
    }
  };

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(Boolean);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const style = {
    position: "absolute",
    transform: {
      xs: "translate(5%, 8%)",
      md: "translate(100%, 8%)",
      lg: "translate(200%, 8%)",
    },
    width: "373px",
    bgcolor: "background.paper",
    border: "1px solid #181e2e",
    boxShadow: 24,
    p: 4,
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    borderRadius: 4,
  };

  return (
    <Box style={mainContainer}>
      <LoadingOverlay isOpen={loading} />

      <Helmet Helmet>
        <title> Personel Grubu İşlemleri | Plancım </title>
      </Helmet>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={[
            style,
            {
              height: undefined,
            },
          ]}
        >
          <Box
            style={{
              marginBottom: "24px",
              width: "100px",
              height: "100px",
              backgroundColor: "#F4F6F8",
              borderRadius: 999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <PersonAddIcon color="success" sx={{ fontSize: "64px" }} />
          </Box>
          <Box style={columncntr}>
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h4"
              sx={{ color: "primary.main", textAlign: "center" }}
            >
              Yeni Personel Grubu Ekle
            </Typography>

            <Box style={dvdr50} />
          </Box>

          <TextField
            name="İsim*"
            label="İsim*"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            style={{ marginBottom: "1rem" }}
          />

          <TextField
            name="Açıklama*"
            label="Açıklama*"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            style={{ marginBottom: "1rem" }}
          />
          {workerIds.length > 0 && (
            <Box direction="column" spacing={1} sx={miniBoxes}>
              {workerIds.slice(0, 5).map((workerId) => {
                const worker = allWorkers.find((w) => w.id === workerId);
                return (
                  <Box key={workerId} style={rlminibox}>
                    <Typography
                      style={{
                        marginRight: "10px",
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      {`${worker.profile.name} ${worker.profile.surname}`}
                    </Typography>
                    <IconButton
                      onClick={() => handleDelete(workerId)}
                      size="small"
                      sx={{ color: "white" }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                );
              })}
              {workerIds.length > 4 && (
                <Typography
                  variant="body1"
                  style={{
                    marginLeft: "10px",
                    color: "#383838",
                    fontSize: 24,
                    fontWeight: "bold",
                  }}
                >
                  . . .
                </Typography>
              )}
            </Box>
          )}

          <FormControl sx={{ marginBottom: "1rem", width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Personel Seçiniz
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={workerIds}
              multiple
              onChange={handleSelectChange}
              input={<OutlinedInput label="Personel Seçiniz*" />}
              renderValue={(selected) =>
                selected
                  .map((id) => {
                    const worker = allWorkers.find(
                      (worker) => worker.id === id
                    );
                    return `${worker.profile.name} ${worker.profile.surname}`;
                  })
                  .join(", ")
              }
              MenuProps={{
                PaperProps: {
                  onScroll: handleScroll, // Bu fonksiyonu tanımladığınızdan emin olun
                },
              }}
            >
              {allWorkers.map((res) => (
                <MenuItem key={res.id} value={res.id}>
                  <Checkbox checked={workerIds.indexOf(res.id) > -1} />
                  <ListItemText
                    primary={`${res.profile.name} ${res.profile.surname}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              if (modalType == "edit") {
                updateUserById();
              } else {
                if (name == "" || description == "") {
                  setAlertMessage("İsim boş girilemez.");
                  setAlertStatus(false);
                  handleAlertClick();
                } else {
                  addWorkerGroup();
                }
              }
            }}
            color="success"
            style={{ color: "white", marginTop: "1rem" }}
          >
            {modalType == "edit" ? (
              <span>Güncelle</span>
            ) : (
              <span>Yeni Personel Grubu Ekle</span>
            )}
          </Button>

          <Typography
            sx={{
              color: "grey[400]",
              fontSize: 14,
              alignSelf: "center",
              marginTop: "0.5rem",
            }}
          >
            İsim boş girilemez.
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={[
            style,
            {
              width: "%30",
              height: "30%",
              top: "25%",
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Box
            style={{
              marginBottom: "24px",
              width: "100px",
              height: "100px",
              backgroundColor: "#F4F6F8",
              borderRadius: 999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DeleteForeverIcon color="error" sx={{ fontSize: "64px" }} />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h3"
            sx={{
              color: "primary.main",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            Personel grubunu silmek istediğinize emin misiniz ?
          </Typography>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="success"
              sx={{
                color: "white",
                marginRight: "1rem",
                fontSize: 16,
                borderRadius: 16,
                width: "120px",
              }}
              endIcon={<CheckIcon />}
              onClick={() => deleteUser()}
            >
              Evet
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                color: "error",
                marginRight: "1rem",
                fontSize: 16,
                borderRadius: 16,
                width: "120px",
              }}
              endIcon={<CloseIcon />}
              onClick={() => setDeleteModal(false)}
            >
              Vazgeç
            </Button>
          </Box>
        </Box>
      </Modal>

      {detailModal && (
        <WorkerGroupModal
          setDetailModal={setDetailModal}
          detailModal={detailModal}
          id={detailId}
        />
      )}

      <PageHeader
        title={"PERSONEL GRUBU İŞLEMLERİ"}
        subtitle={"Personel > Personel Grubu İşlemleri"}
      />
      <MaterialReactTable
        localization={MRT_Localization_TR}
        columns={columns}
        enableColumnActions={false}
        data={data}
        enableRowSelection
        muiTableBodyCellProps={{
          sx: {
            color: "primary.main",
            fontWeight: "600",
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            color: "primary.main",
            letterSpacing: "1px",
            fontWeight: "600",
            opacity: "0.8",
          },
        }}
        positionToolbarAlertBanner="top"
        enablePagination={false}
        positionActionsColumn="last"
        enableRowActions
        renderBottomToolbar={({ table, index }) => (
          <Box sx={tolbr}>
            <Box style={paginationCont}>
              <Box sx={inPag}>
                <IconButton
                  onClick={() => {
                    if (getAllPage > 1) {
                      setGetAllPage(getAllPage - 1);
                    }
                  }}
                >
                  <LeftIcon />
                </IconButton>
                <IconButton
                  sx={{ transform: "scaleX(-1) " }}
                  onClick={() => setGetAllPage(getAllPage + 1)}
                >
                  <LeftIcon />
                </IconButton>
                <FormControl
                  size="small"
                  sx={{ width: "75px", minWidth: "50px" }}
                >
                  <InputLabel id="demo-simple-select-label">Sayfa</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={getAllPage}
                    label="Sayfa"
                    onChange={handleChange}
                  >
                    {Array.from({ length: totalPage }, (_, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        )}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            setDetailId(row.original.ID);
            setDetailModal(!detailModal);
          },
          sx: {
            cursor: "pointer", // Optional: Add a pointer cursor to indicate clickability
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)", // Optional: Add a hover effect
            },
          },
        })}
        renderRowActions={({ row, table }) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              flexDirection: "row",
              gap: "1rem",
              width: "100px",
            }}
          >
            <Tooltip title="Detay">
              <IconButton
                onClick={() => {
                  setDetailId(row.original.ID);
                  setDetailModal(!detailModal);
                }}
              >
                <VisibilityIcon
                  color={"warning.secondary"}
                  style={{ fontSize: "24px" }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Düzenle">
              <IconButton
                onClick={() => {
                  getUserById(row.original.ID);
                  setModalType("edit");
                  setSelectedIdUpdate(row.original.ID);
                }}
              >
                <EditIcon color={"success"} style={{ fontSize: "24px" }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Sil">
              <IconButton
                onClick={() => {
                  setDeleteModal(true);
                  setSelectedId(row.original.ID);
                }}
              >
                <DeleteForeverIcon
                  color={"error"}
                  style={{ fontSize: "24px" }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        //customize built-in buttons in the top-right of top toolbar
        renderTopToolbarCustomActions={({ table }) => (
          <Box style={renderTopToolbarCustomActionsCont}>
            <TextField
              name="Arama"
              label="Arama.."
              value={search}
              onKeyDown={handleSearch}
              onChange={(e) => {
                const { value } = e.target;
                setSearch(value);
                handleSearch(value);
              }}
              sx={{
                marginRight: "12px",
              }}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              id="basic-button"
              aria-controls={openSort ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openSort ? "true" : undefined}
              onClick={handleClickSort}
              sx={{ color: "primary.main", marginRight: "1rem" }}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Sırala
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElSort}
              open={openSort}
              onClose={() => handleCloseSort(sortingType)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleCloseSort("name")}>
                İsime Göre
              </MenuItem>
            </Menu>
            <Button
              sx={{ color: "primary.main", marginRight: "1rem" }}
              id="basic-button"
              endIcon={<FilterAltIcon />}
            >
              Filtrele
            </Button>
          </Box>
        )}
        renderToolbarInternalActions={({ table }) => (
          <Box style={paginationCont}>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleOpen}
              size="small"
              sx={{
                backgroundColor: "success.main",
                fontSize: { xs: "10px", md: "12px", lg: "14px" },
              }}
            >
              Yeni Personel Grubu
            </Button>
          </Box>
        )}
      />

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default WorkerGroup;
