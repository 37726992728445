import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Turk from "./geojson/Turkey.json";
import L from "leaflet"; // Leaflet'in 'L' nesnesini içe aktarın
import { Box, Button, List, ListItem } from "@mui/material";
const initialCenter = [39, 35]; // Haritanın başlangıç konumu
const initialZoom = 6; // Haritanın başlangıç zoom seviyesi
const LeafletMap = () => {
  const [currentIlData, setCurrentIlData] = useState(null);
  const [buttonType, setButtonType] = useState("Meteorolojik Veriler");

  const onEachFeature = (feature, layer) => {
    layer.on({
      click: () => onFeatureClick(feature),
    });
  };

  const onFeatureClick = (feature) => {
    const ilAdi = feature.properties.name;

    // Seçili ilin GeoJSON dosyasını dinamik olarak içe aktar
    import(`./geojson/${ilAdi}.json`)
      .then((module) => {
        console.log(module);
        setCurrentIlData(module.default);
        console.log("Current Il Data:", module.default);
      })
      .catch((error) => {
        console.error("Error loading geojson data:", error);
      });
  };

  const ZoomToBounds = ({ data }) => {
    const map = useMap();

    useEffect(() => {
      if (data) {
        const geoJsonLayer = new L.GeoJSON(data);
        map.fitBounds(geoJsonLayer.getBounds()); // Haritayı sınırlarına sığdırır
      }
    }, [data, map]);

    return data ? (
      <GeoJSON data={data} style={{ color: "#0087c7", weight: 1 }} />
    ) : null;
  };

  const ZoomToBoundsTurkey = ({ data }) => {
    const map = useMap();

    useEffect(() => {
      if (data) {
        const geoJsonLayer = new L.GeoJSON(data);
        map.fitBounds(geoJsonLayer.getBounds()); // Haritayı sınırlarına sığdırır
      }
    }, [data, map]);

    return data ? (
      <GeoJSON
        data={data}
        onEachFeature={onEachFeature}
        style={{ color: "#2A3042", weight: 1 }}
      />
    ) : null;
  };

  const handleBackButtonClick = () => {
    setCurrentIlData(null);
  };

  const handleSwitch = (item) => {
    setButtonType(item);
  };

  return (
    <Box
      style={{
        borderRadius: "24px",
        alignItems: "center",
        width: currentIlData != null ? "100%" : "68.5%",
        height: "550px",
        justifyContent: "space-between",
        backgroundColor: "white",
        marginTop: "24px",
        marginLeft: "24px",
        padding: "8px 8px 8px 8px",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <MapContainer
        center={initialCenter}
        zoom={initialZoom}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "16px",
          borderTopRightRadius: currentIlData != null ? 0 : null,
          borderBottomRightRadius: currentIlData != null ? 0 : null,
        }}
        zoomControl={false}
        scrollWheelZoom={false}
        dragging={false}
        touchZoom={false}
        doubleClickZoom={false}
        boxZoom={false}
        keyboard={false}
      >
        {currentIlData != null && (
          <Button
            style={{
              position: "absolute",
              top: 50,
              left: 50,
              zIndex: 444,
              textTransform: "none",
            }}
            variant="contained"
            onClick={() => handleBackButtonClick()}
            size="small"
          >
            Geri Dön
          </Button>
        )}

        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {currentIlData == null && <ZoomToBoundsTurkey data={Turk} />}
        {currentIlData && <ZoomToBounds data={currentIlData} />}
      </MapContainer>
      {currentIlData != null && (
        <Box
          sx={{
            width: "50%",
            height: "100%",
            marginLeft: "12px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="contained"
              sx={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                textTransform: "none",
                backgroundColor:
                  buttonType != "Meteorolojik Veriler" ? "text.disabled" : null,
              }}
              size="small"
              onClick={() => handleSwitch("Meteorolojik Veriler")}
            >
              Meteorolojik Veriler
            </Button>
            <Button
              variant="contained"
              sx={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                backgroundColor:
                  buttonType == "Meteorolojik Veriler" ? "text.disabled" : null,
                textTransform: "none",
              }}
              size="small"
              onClick={() => handleSwitch("Firma Bul")}
            >
              Firma Bul
            </Button>
          </Box>
          {/* 
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Photos" secondary="Jan 9, 2014" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <WorkIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Work" secondary="Jan 7, 2014" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <BeachAccessIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Vacation" secondary="July 20, 2014" />
      </ListItem>
    </List> */}
        </Box>
      )}
    </Box>
  );
};

export default LeafletMap;
