import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import {
  Box,
  Grid,
  IconButton,
  ListItem,
  Modal,
  Typography,
} from "@mui/material";
import { newModalStyle, subTitle, titleStyle } from "src/theme/customStyles";
import CloseIcon from "@mui/icons-material/Close";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import { modalStyleNewest } from "src/utils/cssStyles";

export default function MaterialDetail({
  modalVisible,
  setModalVisible,
  type,
  id,
}) {
  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState({ items: [] });

  const handleClick = (id) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [id]: !prevOpen[id],
    }));
  };

  React.useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));
    myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
    myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://dev.plancim.com/api/material/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setData(result);
      })
      .catch((error) =>
        console.error("API'den veri alınırken bir hata oluştu:", error)
      );
  }, [id]);

  if (!data) {
    return <LoadingOverlay isOpen={true} />;
  }

  return (
    <>
      {data != [] && (
        <Modal
          open={modalVisible}
          onClose={() => setModalVisible(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={[modalStyleNewest]}>
            <Box
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography style={titleStyle}>DETAY</Typography>
              <IconButton
                sx={{ alignSelf: "flex-end", marginLeft: "auto" }}
                color="black"
                onClick={() => setModalVisible(false)}
              >
                <CloseIcon sx={{ fontSize: "32px" }} />
              </IconButton>
            </Box>
            <Typography style={subTitle}>{type} Detayları</Typography>

            <Box
              sx={{
                backgroundColor: "background.paper",
                display: "flex",
                padding: 4,
                flexDirection: "column",
                borderRadius: 6,
                width: "343px",
                maxWidth: 360,
                gap: "1rem",
                marginTop: "32px",
                justifyContent: "center",
              }}
            >
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                aria-labelledby="material-list-subheader"
              >
                {data.items &&
                  data.items.map((item) => (
                    <>
                      <ListItem key={item.id} divider>
                        <ListItemText
                          primary={
                            item.title?.name
                              ? `Ana Başlık: ${item.title?.name}`
                              : `Ana Başlık: Alt Başlık girilmemiştir.`
                          }
                        />
                      </ListItem>
                      <ListItem key={item.id} divider>
                        <ListItemText
                          primary={
                            item.type?.name
                              ? `Tip: ${item.type?.name}`
                              : `Tip: Alt Başlık girilmemiştir.`
                          }
                        />
                      </ListItem>
                    </>
                  ))}
              </List>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
