import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {
  columncntr,
  dvdr50,
  dvdr50yzd,
  dvdrDik,
  modalStyle,
  subTitle,
  text14,
  title14,
  title20,
  titleStyle,
} from "src/theme/customStyles";

import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
function UserDetailModal(props) {
  const [modalVisible, setModalVisible] = React.useState(props.detailModal);
  const [detail, setDetail] = React.useState(props.allInfoById);

  React.useEffect(() => {
    props.setDetailModal(modalVisible);
    console.log(props.allInfoById);
  }, [modalVisible]);

  const months = [
    { name: "Ocak", color: "info.lighter", value: "?" },
    { name: "Şubat", color: "secondary.lighter", value: "?" },
    { name: "Mart", color: "info.lighter", value: "?" },
    { name: "Nisan", color: "secondary.lighter", value: "?" },
    { name: "Mayıs", color: "info.lighter", value: "?" },
    { name: "Haziran", color: "secondary.lighter", value: "?" },
    { name: "Temmuz", color: "info.lighter", value: "?" },
    { name: "Ağustos", color: "secondary.lighter", value: "?" },
    { name: "Eylül", color: "info.lighter", value: "?" },
    { name: "Ekim", color: "secondary.lighter", value: "?" },
    { name: "Kasım", color: "info.lighter", value: "?" },
    { name: "Aralık", color: "secondary.lighter", value: "?" },
  ];

  return (
    <>
      {detail != [] && (
        <Modal
          open={modalVisible}
          onClose={() => setModalVisible(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={[
              modalStyle,
              {
                width: "90%",
                maxWidth: "960px", // Maksimum genişlik
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#F0F3FF",
                top: "5%",
              },
            ]}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={titleStyle}>DETAY</Typography>
              <IconButton
                sx={{ alignSelf: "flex-end" }}
                color="black"
                onClick={() => setModalVisible(false)}
              >
                <CloseIcon sx={{ fontSize: "32px" }} />
              </IconButton>
            </Box>
            <Typography sx={subTitle}>{props.title} Detayları</Typography>

            <Box
              sx={{
                backgroundColor: "#f8f9fa",
                borderRadius: "12px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                width: "100%", // Ekran genişliğine uyum sağlaması için % olarak ayarlandı.
                maxWidth: "450px", // Maksimum genişlik sınırlaması eklendi.
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
                marginTop: "2rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                Profil Bilgileri
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#e9ecef",
                  borderRadius: "50%",
                  width: "100px",
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "16px",
                }}
              >
                <img
                  alt="avatar"
                  src={
                    detail?.profile.pic
                      ? detail?.profile.pic
                      : detail.profile.gender === "m"
                      ? "/assets/icons/PersonelAssets/employee.png"
                      : "/assets/icons/PersonelAssets/employeewoman.png"
                  }
                  style={{
                    borderRadius: "50%",
                    width: "90%",
                    height: "90%",
                  }}
                />
              </Box>

              <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                {detail.profile.name} {detail.profile.surname}
              </Typography>

              <Box
                sx={{
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column", // Dikey düzen için flexDirection değiştirildi.
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                    Meslek:
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    {detail.job?.name ?? "Girilmemiş"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                    Telefon Numarası:
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    {detail.communicationInfo.phone?.code}
                    {detail.communicationInfo.phone?.number}
                    {detail.communicationInfo.phone?.number == null &&
                      "Girilmemiş."}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                    Mail Adresi:
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    {detail.communicationInfo?.email}
                    {detail.communicationInfo.email == null && "Girilmemiş."}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                backgroundColor: "#f1f3f5",
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                width: "100%",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#343a40",
                  marginBottom: "1rem",
                }}
              >
                Maaş Bilgileri
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {months.map((month, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: month.color,
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#212529",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {month.name}
                    </Typography>
                    <Typography sx={{ color: "#212529", fontSize: "14px" }}>
                      {month.value}₺
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#343a40",
                  marginTop: "20px",
                }}
              >
                Net Maaş = {detail.salary?.net}₺
              </Typography>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default UserDetailModal;
