import {
  Navigate,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import { LoginForm, VerifyLogin } from "./sections/auth/login";
import VerifyRegister from "./sections/auth/register/VerifyRegister";
import UserPage from "./pages/Personel/UserPage";
import JobsPage from "./pages/Personel/JobsPage";
import DashboardAppPage from "./pages/DashboardAppPage";
import Facilities from "./pages/Tesisler/Facilities";
import Titles from "./pages/Personel/TitlesPage";
import WorkerGroup from "./pages/Personel/WorkerGroup";
import Abonelikler from "./pages/Abonelikler/Abonelikler";
import Kullaniciİslemleri from "./pages/Kullanıcılar/Kullaniciİslemleri";
import Firmalar from "./pages/Firmalar/Firmalar";
import MalzemeBaslik from "./pages/Malzeme/MalzemeBaslik/MalzemeBaslik";
import MalzemeAnaBaslik from "./pages/Malzeme/MalzemeAnaBaslik/MalzemeAnaBaslik";
import MalzemeAltBaslik from "./pages/Malzeme/MalzemeleAltBaslik/MalzemeAltBaslik";
import IlBazliVeriler from "./pages/StatikVeriler/Sehir/IlBazliVeriler";
import LimanVerileri from "./pages/StatikVeriler/LimanVerileri/LimanVerileri";
import HavalimaniVerileri from "./pages/StatikVeriler/HavalimaniVerileri/HavalimaniVerileri";
import GumrukVerileri from "./pages/StatikVeriler/GumrukVerileri/GumrukVerileri";
import DemiryollariVerileri from "./pages/StatikVeriler/DemiryollariVerileri/DemiryollariVerileri";
import Test from "./pages/Test";
import Yetkiler from "./pages/Kullanıcılar/Yetkiler";
import Paket from "./pages/FinansalIslemler/Paket/Paket";
import Main from "./pages/FinansalIslemler/Paket/Main";
import Harita from "./pages/Harita/Harita";
import ForgetPass from "./sections/auth/ForgetPass/ForgetPass";
import IlceBazliVeriler from "./pages/StatikVeriler/Ilce/IlceBazliVeriler";
import TokenGecersiz from "./pages/ErrorPages/TokenGecersiz";
import { useEffect } from "react";
import DonanimIslemleri from "./pages/DonanimIslemleri/DonanimIslemleri";
import DonanimGrupslemleri from "./pages/DonanimIslemleri/DonanimGrupIslemleri";
import HaritaGorunumu from "./pages/StatikVeriler/HaritaGorunumu/HaritaGorunumu";
import JobsTypePage from "./pages/Personel/JobsTypePage";

// ----------------------------------------------------------------------

export default function Router() {
  const location = useLocation();
  const navigate = useNavigate();

  const matchPath = (pathname, paths) => {
    for (let path of paths) {
      const regex = new RegExp(`^${path.replace(/:token/g, "[^/]+")}$`);
      if (regex.test(pathname)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const excludePaths = [
      "/",
      "/TEST",
      "/loginVerify",
      "/registerVerify",
      "/ForgetPassword",
      "/ForgetPassword/:token",
      "/401",
    ];

    if (!matchPath(location.pathname, excludePaths)) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("token"));
      myHeaders.append(
        "X-Organization-Id",
        localStorage.getItem("Organization")
      );
      myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch("https://dev.plancim.com/api/dashboard", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.statusCode == 401) {
            navigate("/401");
          }
        })
        .catch((error) => console.error(error));
    }
  }, [location]);

  const routes = useRoutes([
    {
      path: "/",
      element: <LoginPage />,
      index: true,
    },

    {
      path: "/loginVerify",
      element: <VerifyLogin />,
    },
    {
      path: "/registerVerify",
      element: <VerifyRegister />,
    },
    {
      path: "/ForgetPassword",
      element: <ForgetPass />,
    },
    {
      path: "/ForgetPassword/:token",
      element: <ForgetPass />,
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/Anasayfa" /> },
        { path: "Anasayfa", element: <DashboardAppPage /> },
        { path: "Personel", element: <UserPage /> },
        { path: "PersonelGrubuIslemleri", element: <WorkerGroup /> },
        { path: "MeslekIslemleri", element: <JobsPage /> },
        { path: "MeslekTuruIslemleri", element: <JobsTypePage /> },
        { path: "Tesisler", element: <Facilities /> },
        { path: "Unvanlar", element: <Titles /> },
        { path: "Abonelikler", element: <Abonelikler /> },
        { path: "KullaniciIslemleri", element: <Kullaniciİslemleri /> },
        { path: "Firmalar", element: <Firmalar /> },
        { path: "MalzemeBaslik", element: <MalzemeBaslik /> },
        { path: "MalzemeAnaBaslik", element: <MalzemeAnaBaslik /> },
        { path: "MalzemeAltBaslik", element: <MalzemeAltBaslik /> },
        { path: "IlBazliVeriler", element: <IlBazliVeriler /> },
        { path: "IlceBazliVeriler", element: <IlceBazliVeriler /> },
        { path: "LimanVerileri", element: <LimanVerileri /> },
        { path: "HavalimaniVerileri", element: <HavalimaniVerileri /> },
        { path: "DemiryollariVerileri", element: <DemiryollariVerileri /> },
        { path: "GumrukVerileri", element: <GumrukVerileri /> },
        { path: "Yetkiler", element: <Yetkiler /> },
        { path: "Paket", element: <Main /> },
        { path: "DonanimIslemleri", element: <DonanimIslemleri /> },
        { path: "DonanimGrupIslemleri", element: <DonanimGrupslemleri /> },
        { path: "Harita", element: <HaritaGorunumu /> },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/vendor" /> },
        { path: "404", element: <Page404 /> },
        { path: "401", element: <TokenGecersiz /> },

        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
