import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  alttext,
  dvdr50,
  dvdrDik,
  dvdrfull,
  modalStyle,
  newModalStyle,
  subTitle,
  text14wLeft,
  title14,
  title20,
  titleStyle,
} from "src/theme/customStyles";
import CloseIcon from "@mui/icons-material/Close";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";
import { modalStyleNewest } from "src/utils/cssStyles";

const GumrukDetail = ({ modalVisible, setModalVisible, id, type }) => {
  const [cityDetail, setCityDetail] = useState(null);
  useEffect(() => {
    const fetchCityDetail = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("token"));
      myHeaders.append(
        "X-Organization-Id",
        localStorage.getItem("Organization")
      );
      myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `https://dev.plancim.com/api/static/${type}/${id}`,
          requestOptions
        );
        const result = await response.json();
        setCityDetail(result);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCityDetail();
  }, [id]);

  if (!cityDetail) {
    return <LoadingOverlay isOpen={true} />;
  }

  const { name, address, phone, email, city, district } = cityDetail;

  return (
    <Modal
      open={modalVisible}
      onClose={() => setModalVisible(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={[modalStyleNewest]}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography style={titleStyle}>DETAY</Typography>
          <IconButton
            sx={{ alignSelf: "flex-end", marginLeft: "auto" }}
            color="black"
            onClick={() => setModalVisible(false)}
          >
            <CloseIcon sx={{ fontSize: "32px" }} />
          </IconButton>
        </Box>
        <Typography style={subTitle}>{name} Detayları</Typography>

        <Box
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            flexDirection: "column",
            borderRadius: 6,
            gap: "1rem",
            marginTop: "32px",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={[title14, { display: "flex", textDecoration: "none" }]}
          >
            {" "}
            Şehir : <Typography sx={text14wLeft}> {city.name}</Typography>
          </Typography>
          <Typography
            sx={[title14, { display: "flex", textDecoration: "none" }]}
          >
            {" "}
            İlçe : <Typography sx={text14wLeft}> {district.name}</Typography>
          </Typography>
          <Typography
            sx={[
              title14,
              {
                alignItems: "center",
                display: "flex",
                textDecoration: "none",
                gap: "0.5rem",
              },
            ]}
          >
            Adres:{" "}
            <Typography
              sx={[text14wLeft, { fontSize: "12px", textAlign: "left" }]}
            >
              {address}
            </Typography>
          </Typography>
          <Typography
            sx={[title14, { display: "flex", textDecoration: "none" }]}
          >
            {" "}
            Telefon: <Typography sx={text14wLeft}> {phone}</Typography>
          </Typography>
          <Typography
            sx={[title14, { display: "flex", textDecoration: "none" }]}
          >
            {" "}
            Mail: <Typography sx={text14wLeft}> {email}</Typography>
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default GumrukDetail;
