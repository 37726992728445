export const getJobIds = async (setAllJobIds, page) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", localStorage.getItem("token"));
  myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
  myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(
    `https://dev.plancim.com/api/job?per_page=70&page=${page}&sort_by=name&sort_type=ASC`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      console.log("girdik", page);

      setAllJobIds((prevJobIds) => {
        // Mevcut ID'leri içeren bir set oluştur
        const existingIds = new Set(prevJobIds.map((job) => job.id));

        // Sadece daha önce eklenmemiş ID'lere sahip olan elemanları filtrele
        const newJobIds = result.items.filter(
          (job) => !existingIds.has(job.id)
        );

        // Filtrelenmiş yeni elemanları önceki listeye ekle
        return [...prevJobIds, ...newJobIds];
      });
    })
    .catch((error) => console.log("error", error));
};

// export const getBolgeler = async (setAllJobIds, page) => {
//   var myHeaders = new Headers();
//   myHeaders.append("Authorization", localStorage.getItem("token"));
//   myHeaders.append("X-Organization-Id", localStorage.getItem("Organization"));
//   myHeaders.append("X-User-Id", localStorage.getItem("UserId"));

//   var requestOptions = {
//     method: "GET",
//     headers: myHeaders,
//     redirect: "follow",
//   };

//   fetch(
//     `https://dev.plancim.com/api/boleler?per_page=70&page=${page}&sort_by=name&sort_type=ASC`,
//     requestOptions
//   )
//     .then((response) => response.json())
//     .then((result) => {
//       console.log("girdik", page);

//       setAllJobIds((prevJobIds) => {
//         // Mevcut ID'leri içeren bir set oluştur
//         const existingIds = new Set(prevJobIds.map((job) => job.id));

//         // Sadece daha önce eklenmemiş ID'lere sahip olan elemanları filtrele
//         const newJobIds = result.items.filter(
//           (job) => !existingIds.has(job.id)
//         );

//         // Filtrelenmiş yeni elemanları önceki listeye ekle
//         return [...prevJobIds, ...newJobIds];
//       });
//     })
//     .catch((error) => console.log("error", error));
// };
