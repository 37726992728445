// ----------------------------------------------------------------------
import global from "src/Global";

const account = {
  displayName: global.name,
  email: localStorage.getItem("email"),
  photoURL: "",
  package: global.package,
};

export default account;
